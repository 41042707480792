import { useEffect, useState } from "react";
import { Pagination } from "react-bootstrap";
import { serverPaginationLimitOptions } from "../../../Constants/limitOptions";
import IconGoFirst from "../IconsComponent/IconGoFirst";
import IconGoLast from "../IconsComponent/IconGoLast";
import IconGoNext from "../IconsComponent/IconGoNext";
import IconGoPrev from "../IconsComponent/IconGoPrev";
import styles from "./PaginationStyle.module.scss";

const color = {
  default: "rgba(0,0,0,.54)",
  disable: "rgba(0,0,0,.20)",
};
function ServerPaginationComponent(props) {
  const [page, setPage] = useState(1);
  const [pageItems, setPageItems] = useState([]);
  const [totalPageByApi, setTotalPageByApi] = useState(0);
  const [disableButton, setDisableButton] = useState(false);
  const [pagerange, setPageRange] = useState("");

  const getPagination = (paramTotal, paramPage, paramHandler) => {
    let items = [];
    for (let number = 1; number <= paramTotal; number++) {
      items.push(
        <Pagination.Item
          active={number === paramPage}
          onClick={() => {
            if (props.callApi) {
              paramHandler(number - 1);
            } else {
              paramHandler(number);
            }
          }}
          key={number}
        >
          {number}
        </Pagination.Item>
      );
    }
    return items;
  };
  const getPageRange = () => {
    const start = props?.page * props?.limit + 1;
    const end = Math.min(start + (props?.limit - 1), props?.totalAmount);
    const displayRange = `${start}-${end} of ${props?.totalAmount}`;
    setPageRange(displayRange);
  };
  const onGoFirst = () => {
    if (props.callApi) {
      props.setPage(0);
    }
  };

  const onGoPrev = () => {
    if (props.callApi) {
      if (props.page > 0) {
        props.setPage((prev) => prev - 1);
      }
    }
  };

  const onGoNext = () => {
    if (props.callApi) {
      if (props.page + 1 < totalPageByApi) {
        props.setPage((prev) => prev + 1);
      }
    }
    if (page < totalPageByApi) {
      setPage(page + 1);
    }
  };

  const onGoLast = () => {
    if (props.callApi) {
      props.setPage(totalPageByApi - 1);
    } else {
      setPage(totalPageByApi);
    }
  };

  useEffect(() => {
    if (props.data.length > 0) {
      let total = Math.ceil(props.totalAmount / props.limit);
      setTotalPageByApi(total);
      let items = getPagination(total, props.page + 1, props.setPage);
      setPageItems(props.data.length);
    }
  }, [page, props.data, props.limit]);

  useEffect(() => {
    props.setPage(0);
  }, [props.limit]);

  useEffect(() => {
    getPageRange();
  }, [props.limit, props.page, props.totalAmount]);

  return (
    <div className={styles.paginationContainer}>
      <select
        onChange={(e) => {
          props.setLimit(e.target.value);
        }}
        className={styles.limitSelect}
        value={props.limit}
      >
        {serverPaginationLimitOptions.map((el) => {
          return <option>{el}</option>;
        })}
      </select>
      <div className={styles.paginationNumber}>
        <span>{pagerange}</span>
      </div>
      <div
        className={
          props.page === 0
            ? styles.paginationButtonDisabled
            : styles.paginationButton
        }
        onClick={onGoFirst}
      >
        <IconGoFirst color={props.page === 0 ? color.disable : color.default} />
      </div>
      <div
        className={
          props.page === 0
            ? styles.paginationButtonDisabled
            : styles.paginationButton
        }
        onClick={onGoPrev}
      >
        <IconGoPrev color={props.page === 0 ? color.disable : color.default} />
      </div>
      <div
        className={
          props.page === totalPageByApi
            ? styles.paginationButtonDisabled
            : styles.paginationButton
        }
        onClick={onGoNext}
      >
        <IconGoNext
          color={
            props.page === totalPageByApi - 1 ? color.disable : color.default
          }
        />
      </div>
      <div
        className={
          props.page === totalPageByApi
            ? styles.paginationButtonDisabled
            : styles.paginationButton
        }
        onClick={onGoLast}
      >
        <IconGoLast
          color={
            props.page === totalPageByApi - 1 ? color.disable : color.default
          }
        />
      </div>
    </div>
  );
}

export default ServerPaginationComponent;
