import React from "react";

const IconLocationSolid = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 10 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.99967 0.333984C2.41967 0.333984 0.333008 2.42065 0.333008 5.00065C0.333008 8.50065 4.99967 13.6673 4.99967 13.6673C4.99967 13.6673 9.66634 8.50065 9.66634 5.00065C9.66634 2.42065 7.57967 0.333984 4.99967 0.333984ZM4.99967 6.66732C4.07967 6.66732 3.33301 5.92065 3.33301 5.00065C3.33301 4.08065 4.07967 3.33398 4.99967 3.33398C5.91967 3.33398 6.66634 4.08065 6.66634 5.00065C6.66634 5.92065 5.91967 6.66732 4.99967 6.66732Z"
        fill="#545454"
      />
    </svg>
  );
};

export default IconLocationSolid;
