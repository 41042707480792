import React from "react";

function IconPerformanceIndicator() {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_53217_8904)">
        <path
          d="M8.91129 11.0704L8.66707 10.9337L8.42285 11.0704L4.66644 13.1731L5.5054 8.95073L5.55994 8.67622L5.35446 8.48619L2.19393 5.56344L6.46882 5.05658L6.74676 5.02363L6.86398 4.76948L8.66706 0.860445L10.4701 4.76948L10.5873 5.02363L10.8653 5.05658L15.1401 5.56344L11.9797 8.4862L11.7742 8.67622L11.8287 8.95073L12.6677 13.1731L8.91129 11.0704Z"
          fill="url(#paint0_linear_53217_8904)"
          stroke="#FFC107"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_53217_8904"
          x1="1.05859"
          y1="6.90275"
          x2="16.2755"
          y2="6.90275"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF930F" />
          <stop offset="1" stopColor="#FFF95B" />
        </linearGradient>
        <clipPath id="clip0_53217_8904">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.666748)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default IconPerformanceIndicator;
