import React from "react";

const IconPencilNew = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_33995_202263)">
        <path
          d="M1.75 10.0625V12.25H3.9375L10.3892 5.79833L8.20167 3.61083L1.75 10.0625ZM3.45333 11.0833H2.91667V10.5467L8.20167 5.26167L8.73833 5.79833L3.45333 11.0833ZM12.0808 3.28417L10.7158 1.91917C10.5992 1.8025 10.4533 1.75 10.3017 1.75C10.15 1.75 10.0042 1.80833 9.89333 1.91917L8.82583 2.98667L11.0133 5.17417L12.0808 4.10667C12.3083 3.87917 12.3083 3.51167 12.0808 3.28417Z"
          fill="#545454"
        />
      </g>
      <defs>
        <clipPath id="clip0_33995_202263">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconPencilNew;
