import React from "react";

const IconPlansLimit = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <g clip-path="url(#clip0_44441_9733)">
        <path
          d="M7.33333 1.33398L12.1987 2.85398C12.3344 2.89635 12.453 2.98097 12.5372 3.09551C12.6214 3.21005 12.6667 3.3485 12.6667 3.49065V4.66732H14C14.1768 4.66732 14.3464 4.73756 14.4714 4.86258C14.5964 4.9876 14.6667 5.15717 14.6667 5.33398V10.6673C14.6667 10.8441 14.5964 11.0137 14.4714 11.1387C14.3464 11.2637 14.1768 11.334 14 11.334L11.8533 11.3347C11.5953 11.6747 11.282 11.9747 10.92 12.2213L7.33333 14.6673L3.74667 12.222C3.20835 11.855 2.76783 11.362 2.46343 10.7859C2.15902 10.2099 1.99993 9.56819 2 8.91665V3.49065C2.00008 3.34861 2.04552 3.21031 2.12971 3.09591C2.21389 2.9815 2.33241 2.89698 2.468 2.85465L7.33333 1.33398ZM7.33333 2.72998L3.33333 3.98065V8.91665C3.33324 9.3248 3.42683 9.72753 3.60691 10.0938C3.78699 10.4601 4.04874 10.7801 4.372 11.0293L4.498 11.12L7.33333 13.054L9.85467 11.334H6.66667C6.48986 11.334 6.32029 11.2637 6.19526 11.1387C6.07024 11.0137 6 10.8441 6 10.6673V5.33398C6 5.15717 6.07024 4.9876 6.19526 4.86258C6.32029 4.73756 6.48986 4.66732 6.66667 4.66732H11.3333V3.98065L7.33333 2.72998ZM7.33333 8.00065V10.0007H13.3333V8.00065H7.33333ZM7.33333 6.66732H13.3333V6.00065H7.33333V6.66732Z"
          fill="#545454"
        />
      </g>
      <defs>
        <clipPath id="clip0_44441_9733">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconPlansLimit;
