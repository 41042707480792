import moment from "moment";
import "operational-dashboard/dist/index.css";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { deviceDetect, osName } from "react-device-detect";
import { connect, useSelector } from "react-redux";
import { Route, BrowserRouter as Router, Switch, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { v4 as uuid } from "uuid";
import "./Main.scss";
import { showAlert } from "./Services/showAlert";
// Route import
import {
  ADDCOMPANY,
  ADDLOCATION,
  ADDTEAM,
  ANALYTICS_EVENTS,
  CHANGEPASSWORD,
  CHECKLIST,
  COMPARATIVE_ANALYTICS,
  CUSTOMER_ANALYTICS,
  CUSTOMER_JOURNEYS,
  CUSTOMER_JOURNEY_LIST,
  DRIVE_LIST,
  DRIVE_OPERATIONAL_DASHBOARD,
  EMAIL_VERIFY,
  FORGET,
  HOME,
  LIVE_MONITORING,
  LOGIN,
  LOGOUT,
  MOBILEAPPDOWNLOAD,
  MY_TASK,
  ONBOARD_WO_CAM_MODULE,
  PAGE500,
  PERSON_SUITE,
  POS_ORDER_DETAILS,
  POS_ORDER_LIST,
  SETTINGS,
  SETUPPASSWORD,
  SIGNUP,
  SSOLOGIN,
  SUCCESS,
  TASK,
  TASK_DETAILS,
  TASK_TYPE,
  TASK_TYPE_EVENTS,
  UNDERMAINTENANCE,
  VERIFY,
  WALK_IN,
} from "./Constants";

// Import Redux Actions
import {
  addDeviceInfoToStoreAction,
  addUserDataToStoreAction,
  changeActiveSidebarAction,
  toggleLoaderAction,
} from "./Store/actions";

// Import Access
import {
  LoginRoutesComponent,
  PrivateRouteComponent,
} from "./Common/Components/RouteHandlingComponents";

// Login & Forget
import AddCompany from "./Common/Containers/AddCompany";
import AddTeam from "./Common/Containers/AddTeam";
import changePassword from "./Common/Containers/ChangePassword";
import Forget from "./Common/Containers/Forget";
import Login from "./Common/Containers/Login";
import Singup from "./Common/Containers/Signup";
import LoginSso from "./Common/Containers/SsoLogin";
import Success from "./Common/Containers/Success";
import Verify from "./Common/Containers/Verify";
// Settings
// WoCam

// Task

import TaskList from "./Task/Containers/TaskList";
// Ticketing

// Checklist
import Base from "./Common/Components/Base";
import LoaderComponent from "./Common/Components/LoaderComponent";
import AddLocation from "./Common/Containers/AddLocation";
import Home from "./Common/Containers/Home";
import SetupPassword from "./Common/Containers/SetupPassword";
import LogoutComponent from "./Common/Containers/logout";
import page404 from "./Common/Containers/page404";
import page500 from "./Common/Containers/page500";

// Analytics

// DriveThru
// customer joureny tracking

// for device id
import TasksReport from "./Analytics/Containers/TasksReport";
import TaskListDetails from "./Checklist/Containers/TaskListDetails";
import MobileAppDownload from "./Common/Components/MobileAppDownload";
import RouteChangeListener from "./Common/Components/RouteHandlingComponents/RouteChangeListener";
import CustomerAnalytics from "./CustomerAnalytics/Containers/CustomerAnalytics";
import CustomerJourneysComponent from "./CustomerJourneyTracking/Components/CustomerJourneysComponent";

import ComparativeAnalyticsModule from "./ComparativeAnalytics";
import useTabFocus from "./Common/Hooks/useTabFocus";
import LoadSegmentScript from "./Services/LoadSegmentScript";
import Service from "./Services/Service";
import { faviconUpdate } from "./Services/checkWobotDomain";
import SettingsModule from "./Settings";
import EmailVerifyComponentContainer from "./Settings/Containers/EmailVerify";
import SmartAssistantContainer from "./SmartAssistant";
import { store } from "./Store";
import Walkin from "./WalkIn/Containers/Walkin";
import OnboardDeviceModule from "./Wocam";
import { getThemeColors } from "./themes";
import TaskModule from "./Checklist";
import DriveThruModule from "./Drivethru";
import PersonSuiteModule from "./PersonSuite";
import CustomerJourneyModule from "./CustomerJourneyTracking";
import MyTaskModule from "./MyTask";
import OperationalDashboard from "./Drivethru/Containers/OperationalDashboard";
import UnderMaintenance from "./Common/Components/ErrorPagesComponents/UnderMaintenance";
import LossPreventionModule from "./LossPreventionSuite";
import LiveMonitoring from "./Checklist/Containers/LiveMonitoring";

function App(props) {
  const prevUserId = useSelector((state) => {
    const { _id: userId } = state?.userData?.manifest || {};
    return userId;
  });
  const isTabActive = useTabFocus();
  toast.configure();
  const location = useLocation();

  const createDeviceInfo = () =>
    new Promise((resolve, reject) => {
      try {
        const deviceInfo = {
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          deviceType: osName,
          deviceId: uuid(),
          name: `${deviceDetect().model ? deviceDetect().model : osName}`,
        };
        resolve(deviceInfo);
      } catch (e) {
        reject(e);
      }
    });
  // dev and staging are not discoverable by search engines
  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT !== "app") {
      const metaRobots = document.createElement("meta");
      metaRobots.name = "robots";
      metaRobots.content = "noindex,nofollow";
      document.head.appendChild(metaRobots);
    }
  }, []);

  const { deviceInfo } = props;

  useEffect(async () => {
    if (!deviceInfo) {
      try {
        const newDeviceInfo = await createDeviceInfo();
        props?.addDeviceInfo(newDeviceInfo);
      } catch (e) {
        showAlert(e);
      }
    }
  }, [deviceInfo]);

  useEffect(() => {
    if (window.location.pathname.includes("tickets/details")) {
      const paramsSearch = new URLSearchParams(location.search);
      window.location.href = `/tasks/details/${paramsSearch.get(
        "task"
      )}?location=${paramsSearch.get("location")}&camera=&schedule=&from=1970-01-01&to=${moment(
        new Date()
      ).format("YYYY-MM-DD")}&range=Custom`;
    }
  }, [window.location.pathname]);

  useEffect(() => {
    if (window.location.pathname.includes("/wocam/camera/detail")) {
      window.location.href = `/wocam/cameras/details/${window.location.pathname.split("/")[4]}`;
    }
  }, [location]);

  useEffect(() => {
    // If the user's data changes in the Redux store while the tab is inactive,
    // the changes will be deducted, and the user will be redirected to the home page.
    const unsubscribe = store?.subscribe(() => {
      const { userData } = store?.getState() || {};
      const { _id: currentUserId } = userData?.manifest || {};
      const isUserChanged = currentUserId && prevUserId && currentUserId !== prevUserId;
      if (isUserChanged && !isTabActive) {
        window.location.href = HOME;
      }
    });

    return () => {
      unsubscribe();
    };
  }, [prevUserId, isTabActive]);

  useEffect(() => {
    const { _id: userId } = props?.userData?.user || {};
    if (userId) {
      LoadSegmentScript(true);
      if (window.analytics.page) window.analytics.page();
    }
  }, []);

  useEffect(() => {
    faviconUpdate();
  }, []);

  // Change Theme
  useEffect(() => {
    const theme = getThemeColors(!Service.checkHost("openeye") ? "default" : "openEye");
    Object.keys(theme).forEach((colorName) => {
      document.documentElement.style.setProperty(colorName, theme[colorName]);
    });
  }, []);

  return (
    <Router>
      <RouteChangeListener />
      <LoaderComponent />
      <section className="wobot-dashboard">
        {UNDERMAINTENANCE ? (
          <Switch>
            <Route path="*" component={UnderMaintenance} />
          </Switch>
        ) : (
          <>
            {" "}
            <Switch>
              {/* Login and signup */}
              <LoginRoutesComponent exact path={SIGNUP} component={Singup} />
              <LoginRoutesComponent exact path="/" component={Base} />
              <LoginRoutesComponent exact path={LOGIN} component={Login} />
              <Route exact path={SSOLOGIN} component={LoginSso} />
              <Route exact path={MOBILEAPPDOWNLOAD} component={MobileAppDownload} />
              <LoginRoutesComponent exact path={ADDCOMPANY} component={AddCompany} />
              <PrivateRouteComponent exact path={ADDTEAM} component={AddTeam} />
              <PrivateRouteComponent exact path={ADDLOCATION} component={AddLocation} />
              {/* <PrivateRouteComponent
            exact
            path={CONNECTCAMERA}
            component={ConnectCamera}
          /> */}

              <LoginRoutesComponent exact path={FORGET} component={Forget} />
              <LoginRoutesComponent exact path={[VERIFY, "/changepassword"]} component={Verify} />
              <LoginRoutesComponent exact path={CHANGEPASSWORD} component={changePassword} />
              <Route exact path={SETUPPASSWORD} component={SetupPassword} />
              <LoginRoutesComponent exact path={SUCCESS} component={Success} />
              <PrivateRouteComponent
                path={EMAIL_VERIFY}
                component={EmailVerifyComponentContainer}
              />

              {/* <PrivateRouteComponent exact path={VIEW} component={Views} /> */}

              {/* <PrivateRouteComponent exact path={CAMERA} component={Camera} /> */}
              {/* <PrivateRouteComponent
            exact
            path={CAMERA_DETAIL}
            component={CameraDetails}
          /> */}
              {/* <PrivateRouteComponent
            exact
            path={CAMERA_DETAIL_OLD}
            component={CameraDetails}
          /> */}
              {/* <PrivateRouteComponent
            exact
            path={RECORDER_DETAIL}
            component={RecorderDetails}
          /> */}
              {/* TASKS */}
              <PrivateRouteComponent exact path={TASK} component={TaskList} />

              <PrivateRouteComponent exact path={MY_TASK} component={MyTaskModule} />
              <PrivateRouteComponent exact path={TASK_DETAILS} component={TaskListDetails} />
              <PrivateRouteComponent exact path={LIVE_MONITORING} component={LiveMonitoring} />

              {/* <PrivateRouteComponent
            exact
            path={TICKETING}
            component={TicketingList}
          /> */}
              {/* <PrivateRouteComponent
            exact
            path={TICKETING_DETAILS}
            component={TicketingDetails}
          /> */}

              {/* compliance */}
              {/* <PrivateRouteComponent
            exact
            path={COMPLIANCE_NAVBAR}
            component={ComplianceList}
          /> */}
              {/* <PrivateRouteComponent
            exact
            path={COMPLIANCE_DETAILS}
            component={ComplianceDetails}
          /> */}
              <PrivateRouteComponent path={CHECKLIST} component={TaskModule} />

              {/* <PrivateRouteComponent
            exact
            path={ATTENDANCE_LIST}
            component={AttendanceList}
          /> */}
              <PrivateRouteComponent exact path={LOGOUT} component={LogoutComponent} />
              {/* <PrivateRouteComponent
            exact
            path={ANALYTICS_TICKETS}
            component={TicketsReport}
          /> */}
              {/* <PrivateRouteComponent
            exact
            path={ANALYTICS_TICKETS}
            render={() => <TasksReport module={"ticket"} />}
          /> */}
              {/* <PrivateRouteComponent
            exact
            path={ANALYTICS_EVENTS}
            render={() => <TicketsReport module={"event"} />}
          /> */}
              <PrivateRouteComponent
                exact
                path={ANALYTICS_EVENTS}
                render={() => <TasksReport module="event" />}
              />
              <PrivateRouteComponent
                path={DRIVE_OPERATIONAL_DASHBOARD}
                component={OperationalDashboard}
              />
              <PrivateRouteComponent path={DRIVE_LIST} component={DriveThruModule} />
              <PrivateRouteComponent
                exact
                path={CUSTOMER_ANALYTICS}
                component={CustomerAnalytics}
              />
              <PrivateRouteComponent exact path={WALK_IN} component={Walkin} />
              <PrivateRouteComponent
                exact
                path={CUSTOMER_JOURNEYS}
                component={CustomerJourneysComponent}
              />

              <PrivateRouteComponent
                path={CUSTOMER_JOURNEY_LIST}
                component={CustomerJourneyModule}
              />
              <PrivateRouteComponent path={PERSON_SUITE} component={PersonSuiteModule} />
              <PrivateRouteComponent path={POS_ORDER_LIST} component={LossPreventionModule} />
              <PrivateRouteComponent path={ONBOARD_WO_CAM_MODULE} component={OnboardDeviceModule} />
              <PrivateRouteComponent path={SETTINGS} component={SettingsModule} />
              <PrivateRouteComponent
                path={COMPARATIVE_ANALYTICS}
                component={ComparativeAnalyticsModule}
              />

              <PrivateRouteComponent exact path={HOME} component={Home} />
              <Route path={PAGE500} component={page500} />
              <Route path="*" component={page404} />
            </Switch>
            <SmartAssistantContainer />
          </>
        )}
      </section>
    </Router>
  );
}
const mapStateToProps = (state) => state;
const mapDispatchToProps = (dispatch) => ({
  toggleLoader: (data) => {
    dispatch(toggleLoaderAction(data));
  },
  addDeviceInfo: (data) => {
    dispatch(addDeviceInfoToStoreAction(data));
  },
  addUserDataToStore: (data) => {
    dispatch(addUserDataToStoreAction(data));
  },
  changeActiveSidebar: (data) => {
    dispatch(changeActiveSidebarAction(data));
  },
});

App.propTypes = {
  addDeviceInfo: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
