import React from "react";

const IconScrollerPlay = () => {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6.38086 3.75V14.25L14.6309 9L6.38086 3.75Z" fill="white" />
    </svg>
  );
};

export default IconScrollerPlay;
