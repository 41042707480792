import React, { lazy } from "react";
import PageContainer from "../../../Common/Containers/_layouts/PageContainer";
import { settings } from "../../../Constants/MetaDetails";
import { PAGE_TITLES } from "../../../Services/checkWobotDomain";

const ScheduleComponent = lazy(() =>
  import("../../Components/ScheduleComponent")
);
const ShiftComponent = lazy(() => import("../../Components/ShiftComponent"));

function ScheduleContainer() {
  return (
    <PageContainer
      initialPageTitle={PAGE_TITLES.schedule}
      meta={settings?.schedule?.subtitle}
      withLayout={false}
    >
      <ScheduleComponent />
    </PageContainer>
  );
}

function ShiftContainer() {
  return (
    <PageContainer
      initialPageTitle={PAGE_TITLES.schedule}
      meta={settings?.schedule?.subtitle}
      withLayout={false}
    >
      <ShiftComponent />
    </PageContainer>
  );
}

export { ScheduleContainer, ShiftContainer };
