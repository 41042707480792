import React, { Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Layout from "../Common/Containers/_layouts/Home";
import { COMPARATIVE_ANALYTICS, PAGE404 } from "../Constants";
import ComparativeAnalyticsContainer from "./Containers/ComparativeAnalyticsContainer";
import ComparativeAnalyticsSkeleton from "../Common/Components/SkeletonComponent/MainModulesSkeleton/ComparativeAnalyticsSkeleton";

function ComparativeAnalyticsModule() {
  return (
    <Layout>
      <Switch>
        <Route exact path={COMPARATIVE_ANALYTICS}>
          <Suspense fallback={<ComparativeAnalyticsSkeleton />}>
            <ComparativeAnalyticsContainer />
          </Suspense>
        </Route>
        <Route
          path="*"
          render={() => <Redirect to={{ pathname: PAGE404 }} />}
        />
      </Switch>
    </Layout>
  );
}

export default ComparativeAnalyticsModule;
