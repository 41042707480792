import React from "react";
import Skeleton from "react-loading-skeleton";

function SettingsModuleSkeleton() {
  return (
    <Skeleton count={10} height={50} className="playback-table-skeleton mb-1" />
  );
}

export default SettingsModuleSkeleton;
