import React, { lazy } from "react";
import PageContainer from "../../../Common/Containers/_layouts/PageContainer";
import { settings } from "../../../Constants/MetaDetails";
import { PAGE_TITLES } from "../../../Services/checkWobotDomain";

const AddEmployee = lazy(() =>
  import("../../Components/EmployeeComponent/AddEmployeeComponent")
);
const Employee = lazy(() => import("../../Components/EmployeeComponent"));
const EmployeeDetails = lazy(() =>
  import("../../Components/EmployeeComponent/EmployeeDetailsComponent")
);
const EmployeeProfile = lazy(() =>
  import("../../Components/EmployeeProfileComponent")
);

function AddEmployeeContainer() {
  return (
    <PageContainer
      initialPageTitle={PAGE_TITLES.addEmployee}
      meta="Add a user you wish to collaborate with, and optimize your work-load."
      withLayout={false}
    >
      <AddEmployee />
    </PageContainer>
  );
}

function EmployeeContainer() {
  return (
    <PageContainer
      initialPageTitle={PAGE_TITLES.employeeList}
      meta={settings?.users?.subtitle}
      withLayout={false}
    >
      <Employee />
    </PageContainer>
  );
}

function EmployeeDetailsContainer() {
  return (
    <PageContainer
      initialPageTitle={PAGE_TITLES.employeeDetails}
      meta="Information related to this particular employee."
      withLayout={false}
    >
      <EmployeeDetails />
    </PageContainer>
  );
}

function EmployeeProfileContainer() {
  return (
    <PageContainer
      initialPageTitle={PAGE_TITLES.employeeProfile}
      withLayout={false}
    >
      <EmployeeProfile />
    </PageContainer>
  );
}

export {
  AddEmployeeContainer,
  EmployeeContainer,
  EmployeeDetailsContainer,
  EmployeeProfileContainer,
};
