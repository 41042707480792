import React from "react";

const IconIncidentsZeroState = () => {
  return (
    <svg
      width="76"
      height="73"
      viewBox="0 0 76 73"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.3557 18.419H7.36124C3.65094 18.419 0.615234 21.4403 0.615234 25.133V65.4173C0.615234 69.1101 3.65094 72.1314 7.36124 72.1314H47.8373C51.5476 72.1314 54.5833 69.1101 54.5833 65.4173V40.8096C53.4014 41.2299 52.1726 41.5521 50.9069 41.7663V68.4724H4.29164V22.078H25.3548C25.3048 21.4767 25.2793 20.8684 25.2793 20.2543C25.2793 19.6362 25.3051 19.0241 25.3557 18.419Z"
        fill="#CED4DA"
      />
      <path
        d="M32.8336 36.7352C34.5841 38.2485 36.5781 39.4902 38.7493 40.3942H9.88454V36.7352H32.8336Z"
        fill="#CED4DA"
      />
      <path
        d="M44.7107 43.7512H9.88454V47.4102H44.7107V43.7512Z"
        fill="#CED4DA"
      />
      <path
        d="M31.2187 50.7672H9.88454V53.8152H31.2187V50.7672Z"
        fill="#CED4DA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M57.122 36.5491C54.2754 38.2253 50.9575 39.1867 47.4151 39.1867C36.834 39.1867 28.2563 30.609 28.2563 20.0279C28.2563 9.44681 36.834 0.869141 47.4151 0.869141C57.9962 0.869141 66.5738 9.44681 66.5738 20.0279C66.5738 25.5636 64.2261 30.5509 60.4721 34.0485L74.7655 48.0564C75.5802 48.8548 75.5934 50.1624 74.795 50.9771C73.9965 51.7918 72.6889 51.805 71.8742 51.0066L57.122 36.5491ZM62.5017 20.0275C62.5017 28.3597 55.7472 35.1142 47.415 35.1142C39.0829 35.1142 32.3283 28.3597 32.3283 20.0275C32.3283 11.6954 39.0829 4.94083 47.415 4.94083C55.7472 4.94083 62.5017 11.6954 62.5017 20.0275Z"
        fill="#CED4DA"
      />
    </svg>
  );
};

export default IconIncidentsZeroState;
