import React from "react";

const IconVideoPlayback = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.0498 9.79005L9.9998 7.50005V16.5L13.0498 14.21L15.9998 12L13.0498 9.79005ZM13.0498 9.79005L9.9998 7.50005V16.5L13.0498 14.21L15.9998 12L13.0498 9.79005ZM13.0498 9.79005L9.9998 7.50005V16.5L13.0498 14.21L15.9998 12L13.0498 9.79005ZM10.9998 4.07005V2.05005C8.9898 2.25005 7.1598 3.05005 5.6798 4.26005L7.0998 5.69005C8.20981 4.83005 9.5398 4.25005 10.9998 4.07005ZM5.6898 7.10005L4.2598 5.68005C3.0498 7.16005 2.2498 8.99005 2.0498 11H4.0698C4.2498 9.54005 4.8298 8.21005 5.6898 7.10005ZM4.0698 13H2.0498C2.2498 15.01 3.0498 16.84 4.2598 18.32L5.6898 16.89C4.8298 15.79 4.2498 14.46 4.0698 13ZM5.6798 19.74C7.1598 20.9501 8.9998 21.75 10.9998 21.9501V19.93C9.5398 19.75 8.20981 19.17 7.0998 18.31L5.6798 19.74ZM21.9998 12C21.9998 17.16 18.0798 21.42 13.0498 21.9501V19.93C16.9698 19.41 19.9998 16.05 19.9998 12C19.9998 7.95005 16.9698 4.59005 13.0498 4.07005V2.05005C18.0798 2.58005 21.9998 6.84005 21.9998 12Z"
        fill="black"
        fillOpacity="0.54"
      />
    </svg>
  );
};

export default IconVideoPlayback;
