import React, { Suspense, lazy } from "react";
import {
  Redirect,
  Route,
  Switch,
} from "react-router-dom/cjs/react-router-dom.min";
import {
  EXECUTIVE_TASKS,
  EXECUTIVE_TASKS_DETAILS,
  MY_TASK,
  PAGE404,
} from "../Constants";
import Layout from "../Common/Containers/_layouts/Home";
import MyTaskDetailSkeleton from "../Common/Components/SkeletonComponent/DetailsSkeleton/TaskDetailSkeleton/MyTaskDetails";
import MyTaskSkeleton from "../Common/Components/SkeletonComponent/MainModulesSkeleton/taskSkeleton/MyTask";
import TaskListSkeleton from "../Common/Components/SkeletonComponent/MainModulesSkeleton/taskSkeleton/TaskListSkeleton";

const MyTaskList = lazy(() => import("./Containers/MyTaskList"));
const ExecutiveView = lazy(() => import("./Containers/ExecutiveView"));
const ExecutiveDetails = lazy(() => import("./Containers/ExecutiveDetails"));

function MyTaskModule() {
  return (
    <Layout>
      <Switch>
        <Route path={EXECUTIVE_TASKS_DETAILS} exact>
          <Suspense fallback={<MyTaskDetailSkeleton />}>
            <ExecutiveDetails />
          </Suspense>
        </Route>
        <Route path={EXECUTIVE_TASKS} exact>
          <Suspense fallback={<MyTaskSkeleton />}>
            <ExecutiveView />
          </Suspense>
        </Route>
        <Route path={MY_TASK}>
          <Suspense fallback={<TaskListSkeleton />}>
            <MyTaskList />
          </Suspense>
        </Route>
        <Route
          path="*"
          render={() => <Redirect to={{ pathname: PAGE404 }} />}
        />
      </Switch>
    </Layout>
  );
}

export default MyTaskModule;
