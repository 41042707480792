import "./HelpSection.scss";
import React from "react";
import {
  IconWhatNew,
  IconKnowledge,
  IconSiteStatus,
  IconRaiseTicket,
} from "../../../../Components/IconsComponent/Index";

const getHelpLinks = ({ showModal }) => {
  const helpLinks = {
    top: [
      {
        icon: <IconWhatNew />,
        link: "https://updates.wobot.ai/updates",
        linkLabel: "What’s new",
      },
      {
        icon: <IconKnowledge />,
        link: "https://help.wobot.ai/",
        linkLabel: "Knowledgebase",
      },
      {
        icon: <IconSiteStatus />,
        link: "https://status.wobot.ai/",
        linkLabel: "Site status",
      },
    ],
    bottom: [
      {
        icon: <IconRaiseTicket />,
        btnLabel: "Raise a Support Request",
        showRaiseATicketModal: showModal,
      },
    ],
  };
  return helpLinks;
};

function HelpSection({ handleShowRequestModal }) {
  const helpLinks = getHelpLinks({ showModal: handleShowRequestModal });

  return (
    <div className="help-section">
      <ul className="mb-0">
        {helpLinks.top.map((link) => (
          <li className="help-section__link" key={link.linkLabel}>
            <span>{link.icon}</span>
            <a href={link.link} rel="noreferrer">
              {link.linkLabel}
            </a>
          </li>
        ))}
        <div className="help-section__line" />
        {helpLinks.bottom.map((link) => (
          <li className="help-section__link" key={link.btnLabel}>
            <button
              type="button"
              className="raise-btn-link"
              onClick={link.showRaiseATicketModal}
            >
              <span className="mr-2">{link.icon}</span>
              {link.btnLabel}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default HelpSection;
