import React from "react";

const IconPerson = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 1C4.55 1 5 1.45 5 2C5 2.55 4.55 3 4 3C3.45 3 3 2.55 3 2C3 1.45 3.45 1 4 1ZM4 6C5.35 6 6.9 6.645 7 7H1C1.115 6.64 2.655 6 4 6ZM4 0C2.895 0 2 0.895 2 2C2 3.105 2.895 4 4 4C5.105 4 6 3.105 6 2C6 0.895 5.105 0 4 0ZM4 5C2.665 5 0 5.67 0 7V8H8V7C8 5.67 5.335 5 4 5Z"
        fill="#545454"
      />
    </svg>
  );
};

export default IconPerson;
