import React from "react";

const IconSearchLight = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.33301 0.333008C9.64501 0.333008 12.333 3.02101 12.333 6.33301C12.333 9.64501 9.64501 12.333 6.33301 12.333C3.02101 12.333 0.333008 9.64501 0.333008 6.33301C0.333008 3.02101 3.02101 0.333008 6.33301 0.333008ZM6.33301 10.9997C8.91101 10.9997 10.9997 8.91101 10.9997 6.33301C10.9997 3.75434 8.91101 1.66634 6.33301 1.66634C3.75434 1.66634 1.66634 3.75434 1.66634 6.33301C1.66634 8.91101 3.75434 10.9997 6.33301 10.9997ZM11.9897 11.047L13.8757 12.9323L12.9323 13.8757L11.047 11.9897L11.9897 11.047Z"
        fill="#545454"
      />
    </svg>
  );
};

export default IconSearchLight;
