import React from "react";

const IconCalender = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.33325 3.99967H3.99992V3.33301H2.66659V5.33301H13.3333V3.33301H11.9999V3.99967H10.6666V3.33301H5.33325V3.99967ZM13.3333 6.66634H2.66659V13.333H13.3333V6.66634ZM10.6666 1.99967H5.33325V1.33301H3.99992V1.99967H2.66659C1.93021 1.99967 1.33325 2.59663 1.33325 3.33301V13.333C1.33325 14.0694 1.93021 14.6663 2.66659 14.6663H13.3333C14.0696 14.6663 14.6666 14.0694 14.6666 13.333V3.33301C14.6666 2.59663 14.0696 1.99967 13.3333 1.99967H11.9999V1.33301H10.6666V1.99967ZM5.99992 9.33301H4.66658V7.99967H5.99992V9.33301ZM7.33325 9.33301H8.66659V7.99967H7.33325V9.33301ZM9.99992 9.33301V7.99967H11.3333V9.33301H9.99992ZM4.66658 11.9997H5.99992V10.6663H4.66658V11.9997ZM8.66659 11.9997H7.33325V10.6663H8.66659V11.9997Z"
        fill="#545454"
      />
    </svg>
  );
};

export default IconCalender;
