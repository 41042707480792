import React from "react";

const IconJourney = () => {
  return (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.65008 5.98308C6.5613 5.07186 6.5613 3.59448 5.65008 2.68326C4.73886 1.77203 3.26147 1.77203 2.35025 2.68326C1.43902 3.59448 1.43902 5.07186 2.35025 5.98308L4.00016 7.63297L5.65008 5.98308ZM4.00016 9.51864L1.40744 6.9259C-0.0244839 5.49397 -0.0244839 3.17237 1.40744 1.74044C2.83936 0.308524 5.16096 0.308524 6.5929 1.74044C8.02483 3.17237 8.02483 5.49397 6.5929 6.9259L4.00016 9.51864ZM4.00016 5.33317C3.44788 5.33317 3.00016 4.88546 3.00016 4.33317C3.00016 3.78088 3.44788 3.33317 4.00016 3.33317C4.55245 3.33317 5.00016 3.78088 5.00016 4.33317C5.00016 4.88546 4.55245 5.33317 4.00016 5.33317ZM10.0002 13.633L11.6501 11.9831C12.5613 11.0718 12.5613 9.5945 11.6501 8.68324C10.7388 7.77204 9.2615 7.77204 8.35023 8.68324C7.43903 9.5945 7.43903 11.0718 8.35023 11.9831L10.0002 13.633ZM12.5929 12.9259L10.0002 15.5186L7.40743 12.9259C5.9755 11.494 5.9755 9.17237 7.40743 7.74044C8.83936 6.30852 11.161 6.30852 12.5929 7.74044C14.0248 9.17237 14.0248 11.494 12.5929 12.9259ZM10.0002 11.3332C9.4479 11.3332 9.00016 10.8854 9.00016 10.3332C9.00016 9.7809 9.4479 9.33317 10.0002 9.33317C10.5524 9.33317 11.0002 9.7809 11.0002 10.3332C11.0002 10.8854 10.5524 11.3332 10.0002 11.3332Z"
        fill="#3766E8"
      />
    </svg>
  );
};

export default IconJourney;
