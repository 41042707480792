import PropTypes from "prop-types";
import React, { useCallback, useRef, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import IconSend from "../../../Common/Components/IconsComponent/IconSend";
import IconUploadFile from "../../../Common/Components/IconsComponent/IconUploadFile";
import { SEARCH_OPTIONS } from "../../Resources";
import styles from "../Styles.module.scss";
import MessageTags from "./MessageTags";
import RenderSelectedFile from "./RenderSelectedFiles";

const dummyTags = [
  "Tray with no items",
  "Burner switched off",
  "Staff not wearing hairnet",
];

const sendButton = (isSubmitting) => (
  <OverlayTrigger
    key="1"
    overlay={
      !isSubmitting ? <Tooltip id="tooltip-right">Send</Tooltip> : <div />
    }
  >
    <button
      // onClick={(e) => e.preventDefault()}
      disabled={isSubmitting}
      type="submit"
      className="btn p-0 no-line-height"
    >
      <IconSend
        color="#7e7e7e"
        className={`${isSubmitting ? "cursor-default" : ""}`}
      />
    </button>
  </OverlayTrigger>
);

function MessageInput({ handleMessageSubmit, isSubmitting, searchOption }) {
  const [message, setMessage] = useState("");
  const [selectedFiles, setSelectedFiles] = useState(null);
  const fileInputRef = useRef(null);
  const isShowFileUpload = searchOption?.search === SEARCH_OPTIONS.CAMERA_AUDIT;
  const disableUpload = isSubmitting || selectedFiles;

  const handleChange = (value) => {
    if (isShowFileUpload && selectedFiles) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const imageData = e.target.result;
        const formData = new FormData();
        formData.append("image_file", selectedFiles, selectedFiles.type);
        handleMessageSubmit(imageData, formData);
        setSelectedFiles(null);
        setMessage("");
      };
      reader.readAsDataURL(selectedFiles);
      return;
    }
    if (!isShowFileUpload) {
      if (value.trim() !== "") {
        handleMessageSubmit(value);
        setMessage("");
      }
    }
    setMessage("");
  };

  const handleFileChange = (selected) => {
    setSelectedFiles(selected);
  };

  const handleRemoveFile = useCallback(
    (fileName) => {
      setSelectedFiles(null);
    },
    [setSelectedFiles]
  );
  const onSubmit = (e) => {
    e.preventDefault();
    if (!isSubmitting) {
      handleChange(message);
    }
  };
  const renderFileUpload = () => (
    <>
      <input
        type="file"
        accept=".png, .jpg, .jpeg"
        multiple={false}
        onChange={(e) => {
          handleFileChange(e.target.files[0]);
        }}
        ref={fileInputRef}
        style={{ display: "none" }}
      />

      <OverlayTrigger
        key="1"
        overlay={
          !disableUpload ? (
            <Tooltip id="tooltip-right">Upload</Tooltip>
          ) : (
            <div />
          )
        }
      >
        <button
          onClick={() => fileInputRef.current.click()}
          disabled={disableUpload}
          type="button"
          className={`btn p-0 ${disableUpload ? "cursor-default" : ""}`}
        >
          <IconUploadFile
            className={`${disableUpload ? "cursor-default" : ""}`}
          />
        </button>
      </OverlayTrigger>
    </>
  );
  return (
    <div className={styles["chat-footer"]}>
      <MessageTags
        searchOption={searchOption?.search}
        searchLocation={searchOption?.location}
        handleSelect={handleChange}
        isSubmitting={isSubmitting}
      />
      <div className={styles["chat-input"]}>
        <div>
          <form onSubmit={onSubmit}>
            {isShowFileUpload && renderFileUpload()}
            <input
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Ask anything..."
              className="m-0 w-100"
            />
            {sendButton(isSubmitting)}
            <button hidden type="submit" disabled={isSubmitting}>
              Send
            </button>
          </form>
          <RenderSelectedFile
            handleRemoveFile={handleRemoveFile}
            selectedFiles={selectedFiles}
          />
        </div>
      </div>
    </div>
  );
}
MessageInput.propTypes = {
  handleMessageSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  searchOption: PropTypes.shape({
    search: PropTypes.string,
    location: PropTypes.string,
  }).isRequired,
};
export default MessageInput;
