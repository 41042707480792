import React from "react";
import Skeleton from "react-loading-skeleton";
import PageTitleSkeleton from "../../../Molecule/PageTitleSkeleton";

function PersonSuitListSkeleton() {
  return (
    <div className="wobot-panel-main">
      <div className="main-body-wrapper">
        <PageTitleSkeleton />

        <div className="">
          <Skeleton width={270} height={40} />
        </div>
        <div className="list-inline-item mt-4">
          <Skeleton width={500} height={230} />
        </div>

        <div className="panel-body mt-60">
          <div id="table-content">
            <Skeleton
              count={5}
              height={50}
              className="playback-table-skeleton"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PersonSuitListSkeleton;
