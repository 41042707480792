import React from "react";

const IconPlayCircle = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 21 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M13.9632 10.7783L9.60266 7.60846C9.44263 7.49246 9.23019 7.47499 9.05479 7.56513C8.87799 7.65458 8.76758 7.83627 8.76758 8.03264V14.3702C8.76758 14.5686 8.87799 14.7496 9.05479 14.8391C9.12956 14.8768 9.21132 14.8957 9.29378 14.8957C9.4014 14.8957 9.51041 14.8614 9.60266 14.7936L13.9632 11.6266C14.1009 11.5253 14.1813 11.3688 14.1813 11.2024C14.182 11.0333 14.0995 10.8775 13.9632 10.7783Z"
          fill="black"
        />
        <path
          d="M10.5003 0.692383C4.70021 0.692383 0 5.39259 0 11.1927C0 16.9908 4.70021 21.6896 10.5003 21.6896C16.2991 21.6896 21 16.9901 21 11.1927C21.0007 5.39259 16.2991 0.692383 10.5003 0.692383ZM10.5003 19.9377C5.67016 19.9377 1.75402 16.0236 1.75402 11.1927C1.75402 6.36394 5.67016 2.445 10.5003 2.445C15.3298 2.445 19.2453 6.36324 19.2453 11.1927C19.246 16.0236 15.3298 19.9377 10.5003 19.9377Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="21"
            height="21"
            fill="white"
            transform="translate(0 0.689453)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconPlayCircle;
