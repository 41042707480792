import React from "react";

function IconZone() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
    >
      <g clipPath="url(#clip0_45280_75570)">
        <path
          d="M8.00033 12.8235L10.8287 9.99505C12.3909 8.43298 12.3909 5.90032 10.8287 4.33822C9.26666 2.77612 6.73399 2.77612 5.1719 4.33822C3.6098 5.90032 3.6098 8.43298 5.1719 9.99505L8.00033 12.8235ZM11.7716 10.9379L8.00033 14.7091L4.22909 10.9379C2.14629 8.85511 2.14629 5.47821 4.22909 3.39541C6.31189 1.31261 9.68879 1.31261 11.7716 3.39541C13.8543 5.47821 13.8543 8.85511 11.7716 10.9379ZM3.33366 15.1666H12.667V16.5H3.33366V15.1666Z"
          fill="#A0A0A0"
        />
      </g>
    </svg>
  );
}

export default IconZone;
