import React from "react";

const IconScheduleCalender = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <g clip-path="url(#clip0_44424_5281)">
        <path
          d="M11.333 1.99935H13.9997C14.1765 1.99935 14.3461 2.06959 14.4711 2.19461C14.5961 2.31964 14.6663 2.4892 14.6663 2.66602V13.3327C14.6663 13.5095 14.5961 13.6791 14.4711 13.8041C14.3461 13.9291 14.1765 13.9993 13.9997 13.9993H1.99967C1.82286 13.9993 1.65329 13.9291 1.52827 13.8041C1.40325 13.6791 1.33301 13.5095 1.33301 13.3327V2.66602C1.33301 2.4892 1.40325 2.31964 1.52827 2.19461C1.65329 2.06959 1.82286 1.99935 1.99967 1.99935H4.66634V0.666016H5.99967V1.99935H9.99967V0.666016H11.333V1.99935ZM13.333 5.99935V3.33268H11.333V4.66602H9.99967V3.33268H5.99967V4.66602H4.66634V3.33268H2.66634V5.99935H13.333ZM13.333 7.33268H2.66634V12.666H13.333V7.33268ZM3.99967 8.66602H7.33301V11.3327H3.99967V8.66602Z"
          fill="#545454"
        />
      </g>
      <defs>
        <clipPath id="clip0_44424_5281">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconScheduleCalender;
