import React, { lazy, Suspense } from "react";
import SignupSkeleton from "../Components/SkeletonComponent/CommonSkeleton/SignupSkeleton";

const SetupPasswordComponent = lazy(() =>
  import("../Components/ForgotPasswordComponent/SetupPasswordComponent")
);

function SetupPassword() {
  return (
    <Suspense fallback={<SignupSkeleton rowReverse bannerWidth="50vw" />}>
      <SetupPasswordComponent />
    </Suspense>
  );
}

export default SetupPassword;
