import { applyMiddleware, createStore } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import { encryptTransform } from "redux-persist-transform-encrypt";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import {
  createStateSyncMiddleware,
  initMessageListener,
} from "redux-state-sync";
import { TOGGLE_LOADER } from "./constants";
import rootReducer from "./reducers";

const persistConfig = {
  key: "root",
  storage,
  transforms: [
    encryptTransform({
      secretKey: process.env.REACT_APP_SECRET,
      onError() {},
    }),
  ],
};
const config = {
  blacklist: ["persist/PERSIST", "persist/REHYDRATE", TOGGLE_LOADER],
};
const middlewares = [createStateSyncMiddleware(config)];

const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(
  persistedReducer,
  {},
  applyMiddleware(...middlewares)
);
const persistor = persistStore(store);
initMessageListener(store);

export { persistor, store };
