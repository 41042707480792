import React from "react";

function IconUserStatus() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_40484_55767)">
        <path
          d="M8.00001 1.33301C4.32001 1.33301 1.33334 4.31967 1.33334 7.99967C1.33334 11.6797 4.32001 14.6663 8.00001 14.6663C11.68 14.6663 14.6667 11.6797 14.6667 7.99967C14.6667 4.31967 11.68 1.33301 8.00001 1.33301ZM8.00001 13.333C5.06001 13.333 2.66668 10.9397 2.66668 7.99967C2.66668 5.05967 5.06001 2.66634 8.00001 2.66634C10.94 2.66634 13.3333 5.05967 13.3333 7.99967C13.3333 10.9397 10.94 13.333 8.00001 13.333ZM11.06 5.05301L6.66668 9.44634L4.94001 7.72634L4.00001 8.66634L6.66668 11.333L12 5.99967L11.06 5.05301Z"
          fill="#545454"
        />
      </g>
    </svg>
  );
}

export default IconUserStatus;
