import React from "react";
import { Modal } from "react-bootstrap";
import IconClose from "../../../IconsComponent/IconClose";
import styles from "./Medium.module.scss";
const MediumModal = (props) => {
  return (
    <Modal
      size={props.modalSize}
      show={props.visibility}
      onHide={props.onHide}
      dialogClassName={`${styles.mediumModalWidth} ${props.className}`}
      aria-labelledby="contained-modal-title-vcenter"
      // centered
    >
      <Modal.Header className={`${"border-0 mb-0 align-items-top"}`}>
        <div className={`${"modal-header-block"}`}>
          <div
            className={`${"header-title justify-content-start mb-2 flex-1"}`}
          >
            <h5 className={`${"mb-0 title-tag"}`}> {props.modalTitle}</h5>
            {props.titleAlignElement && (
              <span className={`${"ml-2 fs-14 text-other"}`}>
                {props.titleAlignText}
              </span>
            )}
          </div>
          {props.titleBottomElement && (
            <p className={`${"mb-0 fs-14 fw-400 text-other"}`}>
              {props.titleBottomText}
            </p>
          )}
        </div>
        {props.TopBtnGroup && (
          <div className={`${"modal-header-action"}`}>
            <ul className={`${"lint-inline"}`}>
              <li className={`${"list-inline-item"}`}>
                <button
                  className={`${"btn btn-single-icon btn-sm"}`}
                  onClick={props.onHide}
                >
                  <IconClose />
                </button>
              </li>
              {props.primaryButton && (
                <li className={`${"list-inline-item"}`}>
                  <button
                    className={`${"btn btn-primary btn-sm"}`}
                    onClick={props.onClickPrimary}
                  >
                    <span>{props.primaryBtnText}</span>
                  </button>
                </li>
              )}
            </ul>
          </div>
        )}
      </Modal.Header>
      <Modal.Body className={`${"mb-0 config-container"}`}>
        {/* {showTask ? ( */}
        {props.configBlock && <>{props.children}</>}
        {/* ) : null} */}
      </Modal.Body>
      {props.modalFooter && (
        <Modal.Footer>
          <button
            className={`${"btn btn-textIcon btn-sm mr-2"}`}
            onClick={props.bottomSecondaryBtn}
          >
            {props.bottomSecondaryBtnText}
          </button>
          <button
            className={`${"btn btn-primary btn-sm"}`}
            onClick={props.bottomPrimaryBtn}
          >
            {props.bottomPrimaryBtnText}
          </button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default MediumModal;
