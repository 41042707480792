import React, { lazy, Suspense } from "react";
import LoginSkeleton from "../Components/SkeletonComponent/CommonSkeleton/LoginSkeleton";

const ForgotPasswordComponent = lazy(() =>
  import("../Components/ForgotPasswordComponent")
);

function Forget() {
  return (
    <Suspense fallback={<LoginSkeleton height={360} width={550} />}>
      <ForgotPasswordComponent />
    </Suspense>
  );
}

export default Forget;
