import React from "react";

const IconPrevSkip = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_33329_213666)">
        <path d="M6 6H8V18H6V6ZM9.5 12L18 18V6L9.5 12Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_33329_213666">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconPrevSkip;
