import React from "react";

function IconPlaySquare() {
  return (
    <svg
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.333374 0.662267C0.333374 0.296507 0.636914 0 0.994574 0H13.0055C13.3707 0 13.6667 0.296633 13.6667 0.662267V11.3377C13.6667 11.7035 13.3632 12 13.0055 12H0.994574C0.629407 12 0.333374 11.7034 0.333374 11.3377V0.662267ZM1.66671 1.33333V10.6667H12.3334V1.33333H1.66671ZM6.08131 3.60973L9.33391 5.77813C9.45644 5.8598 9.48957 6.0254 9.40784 6.14793C9.38831 6.1772 9.36317 6.20233 9.33391 6.22187L6.08131 8.39027C5.95877 8.472 5.79317 8.43887 5.71151 8.31633C5.68231 8.27253 5.66671 8.22107 5.66671 8.1684V3.83161C5.66671 3.68433 5.78611 3.56494 5.93337 3.56494C5.98604 3.56494 6.03751 3.58052 6.08131 3.60973Z"
        fill="#545454"
      />
    </svg>
  );
}

export default IconPlaySquare;
