const IconStation = (props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 12 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.71 3.7526L10.06 5.5026H1.94L2.29 3.7526H9.71ZM10.6667 0.835938H1.33333V2.0026H10.6667V0.835938ZM10.6667 2.58594H1.33333L0.75 5.5026V6.66927H1.33333V10.1693H7.16667V6.66927H9.5V10.1693H10.6667V6.66927H11.25V5.5026L10.6667 2.58594ZM2.5 9.0026V6.66927H6V9.0026H2.5Z"
        fill="#545454"
      />
    </svg>
  );
};

export default IconStation;
