import React from "react";
import PropTypes from "prop-types";

function IconArrowRight({ width = "16", height = "16", fill = "#fff" }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4562 5.7921L5.9987 1.33464L7.00044 0.332901L13.168 6.50043L7.00044 12.668L5.9987 11.6662L10.4562 7.20877L0.124569 7.20877V5.7921L10.4562 5.7921Z"
        fill={fill}
      />
    </svg>
  );
}

IconArrowRight.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

export default IconArrowRight;
