export const home = {
  title: "Welcome",
  subtitle:
    "Onboard your existing security cameras and start generating operational insights in real time",
};

export const wocam = {
  cameras: {
    title: "Cameras",
    subtitle: "Onboard and manage your cameras here",
  },
  nvrs: {
    title: "Recorders",
    subtitle:
      "Add your recorders to connect and manage multiple cameras at once",
  },
  playback: {
    title: "Playback",
    subtitle: "View & search all the recorded videos in one place",
  },
  liveView: {
    title: "Live View",
    subtitle: "View multiple live camera feeds together in one place",
  },
};

export const tasks = {
  tasks: {
    title: "Tasks",
    subtitle: "Automate tasks and view generated events",
  },
  myTasks: {
    title: "My Tasks",
    subtitle: "View and complete all the tasks assigned to you",
  },
};

export const tickets = {
  title: "Tickets",
  subtitle: "View all the tickets and alerts generated for tasks here",
};

export const events = {
  title: "Events",
  subtitle: "View all the events and detections generated here",
};

export const analytics = {
  tickets: {
    title: "Tickets",
    subtitle: "Analytics for all tickets raised on your account",
  },
  events: {
    title: "Events",
    subtitle: "Analytics for all events generated for your tasks",
  },
};

export const settings = {
  title: "Settings",
  subtitle: "Manage your account level settings and configuration",
  users: {
    subtitle: "Invite your team to collaborate on tasks, playback and more",
  },
  advanced: {
    subtitle: "Manage your camera stream here",
  },
  regions: {
    subtitle:
      "Add the regions your business is located in (e.g. states or countries)",
  },
  cities: {
    subtitle: "Add the cities your business is located in",
  },
  locations: {
    subtitle: "Add and manage the areas where your business is located in",
    locationDetails: {
      title: "Location Details",
      subtitle: "View and manage the location's details",
    },
  },
  schedule: {
    subtitle: "Manage schedules for running tasks and recordings",
  },
  shift: {
    subtitle: "Manage shifts for detailed shift-wise insights for tasks",
  },
  station: {
    subtitle:
      "Manage the DriveThru stations where cars pause or engage, such as Order and Pickup",
  },
  zone: {
    subtitle:
      "Manage the different zones where customers interact, like Order or POS area",
  },
  integrations: {
    subtitle: "Integrate with third-party services to get more out of Wobot AI",
  },
  company: {
    subtitle: "Update your company logo and details here",
  },
  usageAndPlans: {
    subtitle: "Manage your plan usage and subscription details here",
  },
  woconnectDetails: {
    subtitle: "WoConnect settings",
  },
};

export const driveThru = {
  title: "DriveThru",
  breadcrumb: "DriveThru",
  subtitle: "A single window to track a car's DriveThru journey",
  location: {
    title: "DriveThru Location",
    breadcrumb: "DriveThru",
    subtitle: "An overview on the performance of the location",
  },
  CarJourneys: {
    subtitle: "An overview on the performance of the location",
  },
};
export const carWash = {
  title: "Car Wash",
  breadcrumb: "Car Wash",

  subtitle: "A single window to track a car's car wash journey",
  location: {
    title: "Car Wash Location",
    breadcrumb: "Car Wash",
    subtitle: "An overview on the performance of the location",
  },
  CarJourneys: {
    subtitle: "An overview on the performance of the location",
  },
};

export const walkIn = {
  title: "Customer Journey",
  breadcrumb: "Walk-in",
  subtitle: "Monitor your store customer’s analytics",
  location: {
    title: "Walk-in Location",
    breadcrumb: "Walk-in",
    subtitle:
      "Track store analytics for the selected location. All values mentioned are averages unless specified otherwise.",
  },
  CustomerJourneys: {
    title: "Journey Details",
    subtitle: "An overview on the performance of the location",
  },
};

export const ONBOARD_DEVICE_TYPE_OPTIONS = {
  openEye: {
    title: "OpenEye Cameras",
    description:
      "Select to add multiple cameras connected to an OpenEye recorder",
  },
  publicCamera: {
    title: "Camera (RTSP/HLS)",
    description:
      "Select to add a single camera by providing its connection details",
  },
  recorder: {
    title: "Recorder",
    description:
      "Select to add multiple cameras connected to a single recorder",
  },
  localCamera: {
    title: "Onboard Local Cameras",
    description:
      "Select to onboard cameras in your local network via WoConnect",
  },
};
export const ONBOARD_DEVICE_STEP_META = {
  deviceType: {
    title: "Camera Type",
    description: "Select what type of camera you want to add",
  },
  connectionDetails: {
    title: "Connection Details",
    description: "Select how to connect and add the camera",
  },
  otherDetails: {
    title: "Additional Settings",
    description: "Configure additional settings for the camera(s)",
  },
};
