import React, { lazy } from "react";
import PageContainer from "../../../Common/Containers/_layouts/PageContainer";
import { settings } from "../../../Constants/MetaDetails";
import { PAGE_TITLES } from "../../../Services/checkWobotDomain";

const CameraStreamComponent = lazy(() =>
  import("../../../Common/Components/CameraStreamComponent")
);

function AdvancedSettingsContainer() {
  return (
    <PageContainer
      initialPageTitle={PAGE_TITLES.advancedSettings}
      meta={settings?.advanced?.subtitle}
      withLayout={false}
    >
      <CameraStreamComponent />
    </PageContainer>
  );
}

export default AdvancedSettingsContainer;
