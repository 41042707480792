import React, { lazy } from "react";
import PageContainer from "../../../Common/Containers/_layouts/PageContainer";
import { settings } from "../../../Constants/MetaDetails";

const WoConnectSettings = lazy(() =>
  import(
    "../../Components/LocationComponent/WidgetComponent/ConnectedCardWidget/WoConnectSettings/index"
  )
);

function WoConnectSettingsContainer() {
  return (
    <PageContainer
      initialPageTitle="WoConnect Device | Wobot AI"
      meta={settings?.woconnectDetails?.subtitle}
      withLayout={false}
    >
      <WoConnectSettings />
    </PageContainer>
  );
}

export default WoConnectSettingsContainer;
