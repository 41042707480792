const IconLink = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#3766E8"
        d="M6.667 4v1.333H3.333v7.334h7.334V9.333H12v4a.666.666 0 0 1-.667.667H2.667A.666.666 0 0 1 2 13.333V4.667A.667.667 0 0 1 2.667 4h4ZM14 2v5.333h-1.333V4.275L7.47 9.471 6.53 8.53l5.194-5.196H8.667V2H14Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default IconLink;
