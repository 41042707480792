import PropTypes from "prop-types";
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useReducer,
} from "react";

const SET_SEARCH_HANDLER = "SET_SEARCH_HANDLER";
const SET_PRIMARY_BUTTON_PROPS = "SET_PRIMARY_BUTTON_PROPS";
const SET_RESET = "SET_RESET";

const SettingsContext = createContext();

const dispatchSearchHandler = (payload) => ({
  type: SET_SEARCH_HANDLER,
  payload,
});
const dispatchPrimaryButtonProps = (payload) => ({
  type: SET_PRIMARY_BUTTON_PROPS,
  payload,
});

const dispatchResetState = () => ({
  type: SET_RESET,
  payload: null,
});
const initialState = {
  searchHandler: null,
  isShowSearch: false,
  primaryButton: {
    buttonText: "",
    buttonHandler: null,
    hasAccess: false,
  },
};

const settingsReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_SEARCH_HANDLER:
      return { ...state, searchHandler: payload, isShowSearch: true };
    case SET_PRIMARY_BUTTON_PROPS:
      return {
        ...state,
        primaryButton: {
          buttonText: payload.buttonText,
          buttonHandler: payload.buttonHandler,
          hasAccess: payload.hasAccess,
        },
      };
    case SET_RESET:
      return initialState;
    default:
      return state;
  }
};

export function SettingsLayoutProvider({ children }) {
  const [state, dispatch] = useReducer(settingsReducer, initialState);
  const { searchHandler, ...restState } = state;

  const handleSearchQuery = useCallback(
    (value) => {
      if (searchHandler && typeof searchHandler === "function") {
        searchHandler(value);
      }
    },
    [searchHandler]
  );

  const setSearchHandler = useCallback((handler) => {
    dispatch(dispatchSearchHandler(handler));
  }, []);

  const setPrimaryButtonProps = useCallback((payload) => {
    dispatch(dispatchPrimaryButtonProps(payload));
  }, []);

  const setResetState = useCallback(() => {
    dispatch(dispatchResetState());
  }, []);

  const values = useMemo(
    () => ({
      state: {
        ...restState,
        handleSearchQuery,
        setSearchHandler,
        setPrimaryButtonProps,
        setResetState,
      },
      dispatch,
    }),
    [state]
  );

  return (
    <SettingsContext.Provider value={values}>
      {children}
    </SettingsContext.Provider>
  );
}

/**
 * Object representing the state of the settings.
 * @typedef {Object} SettingsState
 * @property {{ buttonText: string,hasAccess:boolean, buttonHandler: function|null }} primaryButton -
 * The primary button properties.
 * @property {boolean} isShowSearch - To show Search bar.
 * @property {HandleSearchQuery} handleSearchQuery - Function to handle search queries.
 * @property {SetSearchHandler} setSearchHandler - Function to set the search handler.
 * @property {SetPrimaryButtonProps} setPrimaryButtonProps -
 * Function to set primary button properties.
 */

/**
 * Function type for handling search queries.
 * @callback HandleSearchQuery
 * @param {string} value - The search query.
 * @returns {void}
 */

/**
 * Function type for setting the search handler.
 * @callback SetSearchHandler
 * @param {function|null} handler - The search handler function or null.
 * @returns {void}
 */

/**
 * Function type for setting primary button properties.
 * @callback SetPrimaryButtonProps
 * @param {{ buttonText: string, buttonHandler: function|null, hasAccess: boolean }} payload -
 * The primary button properties.
 * @returns {void}
 */

/**
 * Hook for accessing settings context and state.
 * @returns {{
 *   state: SettingsState,
 * }} Object containing settings state.
 * @throws Will throw an error if used outside of a SettingsLayoutProvider.
 */
export const useSettings = () => {
  const context = useContext(SettingsContext);
  if (!context) {
    throw new Error("useSettings must be used within a SettingsLayoutProvider");
  }
  return context;
};
SettingsLayoutProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
