import React, { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { connect } from "react-redux";
import { Redirect, Route, useHistory } from "react-router-dom";
import { DEMO_URL, HOME, LOGIN, LOGOUT } from "../../../Constants";
import Roles from "../../../Services/Roles";
import useTabFocus from "../../Hooks/useTabFocus";
import checkOnboardingRoute from "../SignupComponent/checkOnboardingRoute";

function PrivateRouteComponent(props) {
  const { userData, location } = props;
  const isTabActive = useTabFocus();
  const history = useHistory();

  useEffect(() => {
    if (window.location.pathname.includes("/wocam/camera/detail")) {
      window.location.href = `/wocam/cameras/details/${
        window.location.pathname.split("/")[4]
      }`;
    }
    return history.listen((location) => {
      if (window.analytics) {
        window.analytics.page(location.pathname, {
          title: document.title,
          path: location.pathname,
        });
      }
    });
  }, [history]);

  if (isMobile) {
    const url = window.location.href;
    if (url.includes("wobot://")) return;
    const splitUrl = url.split("/");
    if (
      splitUrl?.includes("playback") ||
      splitUrl?.includes("tasks") ||
      splitUrl?.includes("wocam")
    ) {
      window.location.href = window.location.href.replace(
        "https://",
        "wobot://"
      );
      return;
    }
  }

  if (!userData) {
    // If user data is not available, redirect to login or demo page
    if (window.location.origin === DEMO_URL) {
      return <Redirect to={"/"} />;
    }
    const shouldRedirect =
      location.pathname !== LOGOUT && location.pathname !== LOGIN;
    const redirectState =
      shouldRedirect && isTabActive
        ? { from: `${location.pathname}${location.search}` }
        : null;
    return (
      <Redirect
        to={{
          pathname: LOGIN,
          state: redirectState,
        }}
      />
    );
  }

  // User data is available, check for onboarding route redirection
  const onboardingRoute = checkOnboardingRoute(location.pathname, { userData });
  if (
    userData &&
    !!Object.keys(userData).length &&
    onboardingRoute &&
    onboardingRoute !== location.pathname
  ) {
    return <Redirect to={onboardingRoute} />;
  }

  // Check for valid routes using Roles.authenticateRoutes
  if (Roles.authenticateRoutes(location.pathname)) {
    return <Route {...props} />;
  }

  // Redirect to home page
  return <Redirect to={HOME} />;
}

const mapStateToProps = (state) => state;

export default connect(mapStateToProps)(PrivateRouteComponent);
