import React from "react";
import Skeleton from "react-loading-skeleton";
import BlocksComponent from "../../../../Molecule/Block";
const MyTaskDetailSkeleton = () => {
  return (
    <div className={"ml-5"}>
      <Skeleton className={"mt-5 mb-5"} height={10} width={150} />
      <div className="row ">
        {/*First Block*/}
        {Array(3)
          .fill()
          .map((e, index) => (
            <div className={"col-xl-4 col-lg-6 col-md-6"} key={index}>
              <BlocksComponent>
                <div className={"element-section mb-0 panel-fh"}>
                  <div className={"element-content-wrapper"}>
                    <div className={"d-flex align-self-center"}>
                      <div className={"element-content-block"}>
                        <h6 className={"text-primary mb-1"}>
                          <Skeleton height={20} width={100} />
                        </h6>
                        <p className={"mb-0 fw-500"}>
                          <Skeleton />
                        </p>
                      </div>
                    </div>
                  </div>
                  <tbody className={"data-table-list"}>
                    <Skeleton
                      count={4}
                      width={400}
                      style={{ marginBottom: "15px" }}
                    />
                  </tbody>
                </div>
              </BlocksComponent>
            </div>
          ))}
      </div>
    </div>
  );
};

export default MyTaskDetailSkeleton;
