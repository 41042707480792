import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import styles from "./raiseAticket.module.scss";
import IconAddRounded from "../../../../Components/IconsComponent/IconAddRounded";
import { showAlert } from "../../../../../Services/showAlert";

function UploadMedia({ onUpload }) {
  const [uploadedFile, setUploadedFile] = useState(null);
  const allowedImageFormats = [
    "image/png",
    "image/jpeg",
    "image/gif",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/pdf",
    "application/msword",
  ];

  const handleRemoveFile = () => {
    setUploadedFile(null);
    onUpload("");
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      if (!allowedImageFormats.includes(selectedFile.type)) {
        showAlert("Invalid file type.", "error");
        return;
      }
      // Check file size
      const fileSizeInMB = selectedFile.size / (1024 * 1024);
      if (fileSizeInMB > 5) {
        showAlert("File size exceeds 5 MB limit.", "error");
        return;
      }
      setUploadedFile(selectedFile);
    }

    const file = new FileReader();

    file.onload = () => {
      setUploadedFile(selectedFile);
      onUpload(selectedFile);
    };

    file.readAsArrayBuffer(selectedFile);
  };

  return (
    <>
      <div className={styles.uploadMedia}>
        <Form.Group className={styles.formGroup}>
          <Button
            className={styles.uploadBtn}
            variant="primary"
            size="md"
            onClick={() => document.getElementById("fileInput").click()}
          >
            <>
              <IconAddRounded size={24} />{" "}
              <p className={styles.uploadText}>Choose a file</p>
            </>
          </Button>
          <Form.File
            id="fileInput"
            onChange={handleFileChange}
            style={{ display: "none" }}
          />
        </Form.Group>

        <h6>{uploadedFile?.name || "No file chosen"}</h6>
        {uploadedFile && (
          <>
            <h6>
              {uploadedFile.size / (1024 * 1024) >= 1
                ? `(${(uploadedFile.size / (1024 * 1024)).toFixed(2)} MB)`
                : `(${(uploadedFile.size / 1024).toFixed(2)} KB)`}
            </h6>
            <Button
              variant="link"
              size="md"
              className={styles.removeBtn}
              onClick={handleRemoveFile}
            >
              Remove
            </Button>
          </>
        )}
      </div>
      <small className={styles.helperHint}>Max file size 5 MB</small>
    </>
  );
}

export default UploadMedia;
