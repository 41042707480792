import React from "react";

const IconTrashTag = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.00065 12.6667C4.00065 13.4 4.60065 14 5.33398 14H10.6673C11.4007 14 12.0007 13.4 12.0007 12.6667V4.66667H4.00065V12.6667ZM5.33398 6H10.6673V12.6667H5.33398V6ZM10.334 2.66667L9.66732 2H6.33398L5.66732 2.66667H3.33398V4H12.6673V2.66667H10.334Z"
        fill="#545454"
      />
    </svg>
  );
};

export default IconTrashTag;
