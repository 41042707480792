import React, { Suspense, useMemo } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import GroupedRoutesTab from "../../../../Common/Components/Molecule/GroupedRoutesTab";
import RecorderListSkeleton from "../../../../Common/Components/SkeletonComponent/MainModulesSkeleton/RecorderListSkeleton";
import { PAGE404, SCHEDULE } from "../../../../Constants";
import { SETTINGSVIEWSHIFT } from "../../../../Constants/AccessList";
import Roles from "../../../../Services/Roles";
import {
  ScheduleContainer,
  ShiftContainer,
} from "../../../Containers/Schedule";

function SchedulesLayout() {
  const { pathname } = useLocation();

  const tabListGroup = [
    { key: "schedules", name: "Schedules", link: SCHEDULE },
    {
      key: "shifts",
      name: "Shifts",
      link: `${SCHEDULE}/shifts`,
      hide: !Roles.authenticateViewAndAction(SETTINGSVIEWSHIFT),
    },
  ];

  const accessedTabList = useMemo(
    () => tabListGroup.filter((tab) => !tab.hide),
    [tabListGroup]
  );

  return (
    <GroupedRoutesTab
      currentPath={pathname}
      tabList={accessedTabList}
      tabDataCounts={[]}
    >
      <Switch>
        {/* Schedule */}
        <Route exact path={SCHEDULE}>
          <Suspense fallback={<RecorderListSkeleton hideHeader />}>
            <ScheduleContainer />
          </Suspense>
        </Route>
        {/* Shifts */}
        <Route exact path={`${SCHEDULE}/shifts`}>
          <Suspense fallback={<RecorderListSkeleton hideHeader />}>
            <ShiftContainer />
          </Suspense>
        </Route>
        <Route
          path="*"
          render={() => <Redirect to={{ pathname: PAGE404 }} />}
        />
      </Switch>
    </GroupedRoutesTab>
  );
}

export default SchedulesLayout;
