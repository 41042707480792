import React from "react";
import { render } from "react-dom";
import { BrowserRouter } from "react-router-dom";

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import TagManager from "react-gtm-module";
import App from "./App";
import { persistor, store } from "./Store";
import { tagManagerArgs } from "./Services/gmtService";

if (process.env.REACT_APP_GTM_ID) {
  TagManager.initialize(tagManagerArgs);
}

render(
  <BrowserRouter>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);
