import React from "react";

const IconZoomOut = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.3499 8.2334H5.48883V9.60559H12.3499V8.2334Z"
        fill="#545454"
      />
      <path
        d="M17.1527 15.0944H16.0687L15.6845 14.7239C17.0292 13.1595 17.8388 11.1287 17.8388 8.9194C17.8387 3.99314 13.8456 0 8.9194 0C3.9932 0 0 3.99314 0 8.9194C0 13.8457 3.99314 17.8388 8.9194 17.8388C11.1287 17.8388 13.1595 17.0292 14.7239 15.6845L15.0944 16.0687V17.1527L21.9554 24L24 21.9554L17.1527 15.0944ZM8.9194 15.0944C5.5026 15.0944 2.74445 12.3362 2.74445 8.9194C2.74445 5.5026 5.5026 2.74445 8.9194 2.74445C12.3362 2.74445 15.0944 5.5026 15.0944 8.9194C15.0944 12.3362 12.3362 15.0944 8.9194 15.0944Z"
        fill="#545454"
      />
    </svg>
  );
};

export default IconZoomOut;
