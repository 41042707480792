import React from "react";

function ExternalIconSvg() {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 5.12891V6.62891H3.75V14.8789H12V11.1289H13.5V15.6289C13.5 16.0431 13.1642 16.3789 12.75 16.3789H3C2.58579 16.3789 2.25 16.0431 2.25 15.6289V5.87891C2.25 5.4647 2.58579 5.12891 3 5.12891H7.5ZM15.75 2.87891V8.87891H14.25L14.2499 5.43866L8.40532 11.2842L7.34467 10.2236L13.1887 4.37891H9.75V2.87891H15.75Z"
        fill="#3766E8"
      />
    </svg>
  );
}

export default ExternalIconSvg;
