import React from "react";

const IconShare = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.31374 12.3917L5.05541 10.0692C4.64666 10.4749 4.12692 10.7506 3.56174 10.8614C2.99655 10.9722 2.41121 10.9132 1.87951 10.6918C1.34782 10.4704 0.893571 10.0965 0.574036 9.61737C0.2545 9.13819 0.0839844 8.57514 0.0839844 7.9992C0.0839844 7.42325 0.2545 6.8602 0.574036 6.38102C0.893571 5.90185 1.34782 5.528 1.87951 5.30661C2.41121 5.08523 2.99655 5.02621 3.56174 5.13701C4.12692 5.2478 4.64666 5.52345 5.05541 5.9292L9.31374 3.6067C9.1677 2.92148 9.27318 2.20658 9.6109 1.59274C9.94863 0.978902 10.496 0.50714 11.153 0.263725C11.81 0.0203098 12.5326 0.0215086 13.1888 0.267102C13.8449 0.512696 14.3907 0.986272 14.7264 1.60123C15.0621 2.21618 15.1652 2.93143 15.0169 3.61616C14.8686 4.30089 14.4787 4.90935 13.9187 5.33028C13.3586 5.75121 12.6657 5.95647 11.9668 5.90853C11.2678 5.86059 10.6094 5.56265 10.1121 5.0692L5.85374 7.3917C5.93869 7.79224 5.93869 8.20615 5.85374 8.6067L10.1121 10.9292C10.6094 10.4357 11.2678 10.1378 11.9668 10.0899C12.6657 10.0419 13.3586 10.2472 13.9187 10.6681C14.4787 11.089 14.8686 11.6975 15.0169 12.3822C15.1652 13.067 15.0621 13.7822 14.7264 14.3972C14.3907 15.0121 13.8449 15.4857 13.1888 15.7313C12.5326 15.9769 11.81 15.9781 11.153 15.7347C10.496 15.4913 9.94863 15.0195 9.6109 14.4057C9.27318 13.7918 9.1677 13.0769 9.31374 12.3917Z"
        fill="#3766E8"
      />
    </svg>
  );
};

export default IconShare;
