import React from "react";

function IconRefresh() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.45457 12C5.45457 8.38364 8.38366 5.45454 12 5.45454C13.1073 5.45454 14.1437 5.73272 15.06 6.21274L16.6528 4.62C15.3 3.77451 13.7127 3.27271 12 3.27271C7.17817 3.27271 3.27273 7.17815 3.27273 12H0L4.36362 16.3636L8.72725 12H5.45457Z"
        fill="#545454"
      />
      <path
        d="M19.6364 7.63623L15.2728 11.9999H18.5455C18.5455 15.6162 15.6164 18.5453 12 18.5453C10.8928 18.5453 9.8564 18.2671 8.94004 17.7871L7.34729 19.3799C8.7 20.2253 10.2873 20.7271 12 20.7271C16.8218 20.7271 20.7273 16.8217 20.7273 11.9999H24.0001L19.6364 7.63623Z"
        fill="#545454"
      />
    </svg>
  );
}

export default IconRefresh;
