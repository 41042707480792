import React from "react";

const IconPlayCircleWhite = () => {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8 5V19L19 12L8 5Z" fill="#ffffff" />
    </svg>
  );
};

export default IconPlayCircleWhite;
