import React from "react";

const IconTimerBold = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.00033 17.3346C4.39783 17.3346 0.666992 13.6038 0.666992 9.0013C0.666992 4.3988 4.39783 0.667969 9.00033 0.667969C13.6028 0.667969 17.3337 4.3988 17.3337 9.0013C17.3337 13.6038 13.6028 17.3346 9.00033 17.3346ZM9.00033 15.668C10.7684 15.668 12.4641 14.9656 13.7144 13.7153C14.9646 12.4651 15.667 10.7694 15.667 9.0013C15.667 7.23319 14.9646 5.5375 13.7144 4.28726C12.4641 3.03701 10.7684 2.33464 9.00033 2.33464C7.23222 2.33464 5.53652 3.03701 4.28628 4.28726C3.03604 5.5375 2.33366 7.23319 2.33366 9.0013C2.33366 10.7694 3.03604 12.4651 4.28628 13.7153C5.53652 14.9656 7.23222 15.668 9.00033 15.668ZM9.83366 9.0013H13.167V10.668H8.16699V4.83464H9.83366V9.0013Z"
        fill="#545454"
      />
    </svg>
  );
};

export default IconTimerBold;
