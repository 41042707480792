import React from "react";
import "./style.scss";
import WobotLogoSvg from "./assets/WobotLogoSvg";
import UnderMaintenanceSvg from "./assets/UnderMaintenanceSvg";
import ExternalIconSvg from "./assets/ExternalIconSvg";

function UnderMaintenance() {
  return (
    <div className="container">
      {/* <CenterBlock /> */}
      <div className="row maintainContainer align-items-center">
        <div className="col-lg-12 justify-content-center">
          <div className="wrapper-block-container">
            <div className="wobotLogo">
              <WobotLogoSvg />
            </div>
            <div className="cherry-img">
              <UnderMaintenanceSvg />
            </div>
            <div className="blockWrapper">
              <div className="block-container">
                <p className="maintenanceHeading">
                  System Maintenance Underway!
                </p>
                <p className="maintenanceDesc">
                  We're currently updating to enhance performance and security.
                  Apologies for any disruptions you may experience. Thank you
                  for your patience!
                </p>
                <p className="maintenanceEta">
                  {" "}
                  Expected Downtime: 11:30 PM to 3:30 AM (EST) / 9 AM to 1 PM
                  (IST)
                </p>

                <a
                  href="https://status.wobot.ai/"
                  rel="noopener noreferrer"
                >
                  <span className="linkTxtContainer">
                    <p>Track Status</p>
                    <ExternalIconSvg />
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UnderMaintenance;
