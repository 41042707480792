import React from "react";

function IconSpeedometer({ height = "16", width = "16", color = "#3766E8" }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.00016 1.33301C11.682 1.33301 14.6668 4.31777 14.6668 7.99967C14.6668 11.6815 11.682 14.6663 8.00016 14.6663C4.31826 14.6663 1.3335 11.6815 1.3335 7.99967C1.3335 4.31777 4.31826 1.33301 8.00016 1.33301ZM8.00016 2.66634C5.05464 2.66634 2.66683 5.05415 2.66683 7.99967C2.66683 10.9452 5.05464 13.333 8.00016 13.333C10.9457 13.333 13.3335 10.9452 13.3335 7.99967C13.3335 5.05415 10.9457 2.66634 8.00016 2.66634ZM10.5554 4.89133C10.7133 4.78053 10.9279 4.79918 11.0643 4.93555C11.2003 5.07157 11.218 5.28604 11.106 5.44246C9.65283 7.47267 8.85323 8.56081 8.7073 8.70681C8.31676 9.09727 7.68356 9.09727 7.29303 8.70681C6.90256 8.31627 6.90256 7.68307 7.29303 7.29254C7.5419 7.04374 8.62936 6.24333 10.5554 4.89133ZM11.6668 7.33301C12.035 7.33301 12.3335 7.63147 12.3335 7.99967C12.3335 8.36787 12.035 8.66634 11.6668 8.66634C11.2986 8.66634 11.0002 8.36787 11.0002 7.99967C11.0002 7.63147 11.2986 7.33301 11.6668 7.33301ZM4.3335 7.33301C4.70168 7.33301 5.00016 7.63147 5.00016 7.99967C5.00016 8.36787 4.70168 8.66634 4.3335 8.66634C3.96531 8.66634 3.66683 8.36787 3.66683 7.99967C3.66683 7.63147 3.96531 7.33301 4.3335 7.33301ZM5.87884 4.93555C6.13919 5.19589 6.13919 5.61801 5.87884 5.87835C5.6185 6.1387 5.19638 6.1387 4.93604 5.87835C4.67568 5.61801 4.67568 5.19589 4.93604 4.93555C5.19638 4.67519 5.6185 4.67519 5.87884 4.93555ZM8.00016 3.66634C8.36836 3.66634 8.66683 3.96482 8.66683 4.33301C8.66683 4.70119 8.36836 4.99967 8.00016 4.99967C7.63196 4.99967 7.3335 4.70119 7.3335 4.33301C7.3335 3.96482 7.63196 3.66634 8.00016 3.66634Z"
        fill={color}
      />
    </svg>
  );
}

export default IconSpeedometer;
