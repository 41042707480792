import React from "react";

const IconStationType = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_40322_4692)">
        <path
          d="M9.18 4.5L9.48 6H2.52L2.82 4.5H9.18ZM10 2H2V3H10V2ZM10 3.5H2L1.5 6V7H2V10H7V7H9V10H10V7H10.5V6L10 3.5ZM3 9V7H6V9H3Z"
          fill="#545454"
        />
      </g>
      <defs>
        <clipPath id="clip0_40322_4692">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconStationType;
