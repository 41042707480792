import React from "react";
import { validateEmail } from "../../../../../Services/validation";
import MediumModal from "../../../../Components/Molecule/ModalBlock/MediumModal";
import styles from "../NavbarComponent/Navbar.module.scss";

function DownloadAppModal({
  show,
  setUserEmail,
  handleClose,
  setTextChange,
  textChange,
  userData,
  userEmail,
  isEmailValid,
  setIsEmailValid,
  onSubmitEmail,
}) {
  // useEffect(() => {
  //   setIsEmailValid(validateEmail(userEmail));
  //   console.log(validateEmail(userEmail), "isEmailValid");
  // }, []);

  return (
    <MediumModal
      modalSize="md"
      visibility={show}
      modalTitle="Take Wobot AI anywhere"
      titleBottomElement
      titleBottomText="Use the Wobot AI mobile app to view events, videos and live camera feed on the go"
      primaryButton={false}
      configBlock
      modalFooter={false}
      TopBtnGroup
      onHide={() => {
        setUserEmail("");
        handleClose();
        setTextChange(true);
        setIsEmailValid(false);
      }}
    >
      <div className={styles.boxWrapper}>
        <div className={styles.boxContent_form}>
          <label>
            Enter your email and we’ll send a download link for the app
          </label>
          <div className={`form-group ${styles.form_group}`}>
            <input
              type="text"
              id="emailadress"
              name="emailadress"
              disabled={false}
              value={
                userData?.user?.email && textChange
                  ? userData?.user?.email
                  : userEmail
              }
              tabIndex={1}
              className={`form-control mr-1 ${isEmailValid ? "error-red" : ""}`}
              placeholder="e.g. john@example.com"
              onChange={(e) => {
                setUserEmail(e.target.value);
                setIsEmailValid(validateEmail(e.target.value));
                setTextChange(false);
              }}
            />
            <button
              className="btn btn-primary btn-md"
              onClick={async () => {
                if (userData?.user?.email && textChange) {
                  await onSubmitEmail(userData?.user?.email);
                  setTextChange(true);
                } else {
                  await onSubmitEmail(userEmail);
                  setTextChange(true);
                }
              }}
            >
              Send
            </button>
          </div>
        </div>
        <div className={styles.boxSeparator}>
          <span>OR</span>
        </div>
        <div className={styles.boxLinkContent}>
          <label>Scan the QR code to download app directly</label>
          <div className={styles.imageWrapper}>
            <img src="/assets/images/qr-code.png" />
          </div>
          <span className={styles.boxNote}>
            Available on iOS and Android platforms
          </span>
          {/* <ul className="list-inline">
              <li className="list-inline-item">
                <a
                  href="https://apps.apple.com/gt/app/wobot-ai-video-intelligence/id1570867920?l=en"
                  target="_blank"
                >
                  <img src="/assets/images/appstore-1.png" />
                </a>
              </li>
              <li className="list-inline-item">
                <a
                  href="https://play.google.com/store/apps/details?id=com.wobot&hl=en_IN&gl=US"
                  target="_blank"
                >
                  <img src="/assets/images/playstore-1.png" />
                </a>
              </li>
            </ul> */}
        </div>
      </div>
    </MediumModal>
  );
}

export default DownloadAppModal;
