import React from "react";
import Skeleton from "react-loading-skeleton";

function PosStatsSkeleton() {
  return (
    <span>
      <div className="list-inline-item w-100 mt-4 mb-2">
        <Skeleton width="25%" height={180} />
        <Skeleton className="ml-2" width="25%" height={180} />
        <Skeleton className="ml-2" width="48%" height={180} />
      </div>
      <div className="d-flex justify-content-end mb-1 mr-3">
        <Skeleton width={200} height={40} />
      </div>
    </span>
  );
}

export default PosStatsSkeleton;
