import React from "react";

const IconNVR = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 24 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.8182 0.181885H2.18184C0.976347 0.181885 0 1.15823 0 2.36367V15.4546C0 16.6601 0.976347 17.6364 2.18184 17.6364H7.6364V19.8183H16.3636V17.6364H21.8182C23.0237 17.6364 23.9891 16.6601 23.9891 15.4546L24 2.36367C24 1.15823 23.0237 0.181885 21.8182 0.181885ZM21.8182 15.4546H2.18184V2.36367H21.8182V15.4546ZM19.6364 5.6364H7.63635V7.81824H19.6364V5.6364ZM19.6364 10.0001H7.63635V12.1819H19.6364V10.0001ZM6.54546 5.6364H4.36362V7.81824H6.54546V5.6364ZM6.54546 10.0001H4.36362V12.1819H6.54546V10.0001Z"
        fill="#545454"
      />
    </svg>
  );
};

export default IconNVR;
