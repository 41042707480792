import React, { Suspense, lazy } from "react";
import ErrorPageSkeleton from "../Components/SkeletonComponent/CommonSkeleton/ErrorPageSkeleton";

const Page500Component = lazy(() =>
  import("../Components/ErrorPagesComponents/Page500Component")
);

const page500 = () => (
  <Suspense fallback={<ErrorPageSkeleton />}>
    <Page500Component />
  </Suspense>
);

export default page500;
