import PropTypes from "prop-types"; // Import PropTypes library
import React from "react";

function IconWopipe({ color }) {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M3.00033 13.3337C1.71166 13.3337 0.666992 12.289 0.666992 11.0003C0.666992 9.71166 1.71166 8.66699 3.00033 8.66699C4.05713 8.66699 4.94984 9.36953 5.23687 10.3331L9.00033 10.3332V9.00033L10.3337 8.99986V5.16254L8.83832 3.66654H5.00033V5.0003H1.00033V1.0003H5.00033V2.33321H8.83832L11.0003 0.171875L13.8287 3.0003L11.667 5.16121V8.99986L13.0003 9.00033V13.0003H9.00033V11.6665L5.23699 11.6671C4.95009 12.6309 4.05728 13.3337 3.00033 13.3337ZM3.00033 10.0003C2.44804 10.0003 2.00033 10.448 2.00033 11.0003C2.00033 11.5526 2.44804 12.0003 3.00033 12.0003C3.55261 12.0003 4.00033 11.5526 4.00033 11.0003C4.00033 10.448 3.55261 10.0003 3.00033 10.0003ZM11.667 10.3337H10.3337V11.667H11.667V10.3337ZM11.0003 2.05749L10.0575 3.0003L11.0003 3.94311L11.9431 3.0003L11.0003 2.05749ZM3.66699 2.33363H2.33366V3.66697H3.66699V2.33363Z" />
    </svg>
  );
}

IconWopipe.propTypes = {
  color: PropTypes.string,
};

IconWopipe.defaultProps = {
  color: "#3766E8",
};

export default IconWopipe;
