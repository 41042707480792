import React from "react";

const IconPeople = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3334 9.1665C14.7167 9.1665 15.825 8.04984 15.825 6.6665C15.825 5.28317 14.7167 4.1665 13.3334 4.1665C11.95 4.1665 10.8334 5.28317 10.8334 6.6665C10.8334 8.04984 11.95 9.1665 13.3334 9.1665ZM6.66671 9.1665C8.05004 9.1665 9.15837 8.04984 9.15837 6.6665C9.15837 5.28317 8.05004 4.1665 6.66671 4.1665C5.28337 4.1665 4.16671 5.28317 4.16671 6.6665C4.16671 8.04984 5.28337 9.1665 6.66671 9.1665ZM6.66671 10.8332C4.72504 10.8332 0.833374 11.8082 0.833374 13.7498V15.8332H12.5V13.7498C12.5 11.8082 8.60837 10.8332 6.66671 10.8332ZM13.3334 10.8332C13.0917 10.8332 12.8167 10.8498 12.525 10.8748C13.4917 11.5748 14.1667 12.5165 14.1667 13.7498V15.8332H19.1667V13.7498C19.1667 11.8082 15.275 10.8332 13.3334 10.8332Z"
        fill="#3766E8"
      />
    </svg>
  );
};

export default IconPeople;
