import PropTypes from "prop-types";
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const WoCamContext = createContext();

function WoCamContextProvider({ children }) {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const [searchQuery, setSearchQuery] = useState(urlParams.get("query") || "");
  const [showCameraOfflineAlert, setShowCameraOfflineAlert] = useState(false);
  const [currentTabDataCount, setTabDataCount] = useState({});

  const handleSearch = (query) => {
    setSearchQuery(query);
  };
  const setCurrentTabDataCount = useCallback(
    (count) => setTabDataCount(count),
    [setTabDataCount]
  );

  const value = useMemo(
    () => ({
      handleSearch,
      searchQuery,
      showCameraOfflineAlert,
      setShowCameraOfflineAlert,
      currentTabDataCount,
      setCurrentTabDataCount,
    }),
    [handleSearch, searchQuery, showCameraOfflineAlert]
  );

  return (
    <WoCamContext.Provider value={value}>{children}</WoCamContext.Provider>
  );
}
WoCamContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
export default WoCamContextProvider;

export function useWoCamContext() {
  return useContext(WoCamContext);
}
