import React from "react";

function IconCameraLine({ width = 16, height = 16, color = "#545454" }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.333 1.66634H10.2197L8.99967 0.333008H4.99967L3.77967 1.66634H1.66634C0.933008 1.66634 0.333008 2.26634 0.333008 2.99967V10.9997C0.333008 11.733 0.933008 12.333 1.66634 12.333H12.333C13.0663 12.333 13.6663 11.733 13.6663 10.9997V2.99967C13.6663 2.26634 13.0663 1.66634 12.333 1.66634ZM12.333 10.9997H1.66634V2.99967H4.36634L5.58634 1.66634H8.41301L9.63301 2.99967H12.333V10.9997ZM6.99967 3.66634C5.15967 3.66634 3.66634 5.15967 3.66634 6.99967C3.66634 8.83967 5.15967 10.333 6.99967 10.333C8.83967 10.333 10.333 8.83967 10.333 6.99967C10.333 5.15967 8.83967 3.66634 6.99967 3.66634ZM6.99967 8.99967C5.89967 8.99967 4.99967 8.09967 4.99967 6.99967C4.99967 5.89967 5.89967 4.99967 6.99967 4.99967C8.09967 4.99967 8.99967 5.89967 8.99967 6.99967C8.99967 8.09967 8.09967 8.99967 6.99967 8.99967Z"
        fill={color}
      />
    </svg>
  );
}

export default IconCameraLine;
