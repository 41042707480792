import PropTypes from "prop-types";
import React from "react";
import { Modal } from "react-bootstrap";
import "./confirm-style.scss";

/**
 * Reusable Confirm Modal component.
 *
 * @component
 * @example
 * // Usage within another component
 * <ConfirmModal
 *   visibility={true}
 *   onHide={() => {}}
 *   onNo={() => {}}
 *   onYes={() => {}}
 *   secondaryBtn="Cancel"
 *   confirmButtonText="Confirm"
 *   actionCondition="confirm"
 *   className="custom-class"
 * >
 *   <div>Confirmation Content</div>
 * </ConfirmModal>
 *
 * @param {Object} props - React component props.
 * @param {boolean} props.visibility - Determines whether the modal is visible or not.
 * @param {boolean} props.modalTitle - Modal title to show on top.
 * @param {boolean} props.modalSubTitle - Modal sub title to show below modal title.
 * @param {Function} props.onHide - Callback function when the modal is closed.
 * @param {React.ReactNode} props.children - Content to be displayed inside the modal body.
 * @param {Function} props.onNo - Callback function when the "Cancel" button is clicked.
 * @param {Function} props.onYes -
 * Callback function when the "Confirm" or "Delete" button is clicked.
 * @param {string} [props.secondaryBtn="Cancel"] - Text for the "Cancel" button.
 * @param {string} [props.confirmButtonText="Confirm"] - Text for the "Confirm" || "Delete" button.
 * @param {string} [props.actionCondition="confirm"] -
 * Condition to determine the action type ('confirm' or 'delete').
 * @param {string} [props.className=""] - Additional class to be applied to the modal dialog.
 * @returns {JSX.Element} JSX element representing the ConfirmModal component.
 */

function ConfirmModal(props) {
  const {
    children,
    onHide,
    onNo,
    onYes,
    visibility,
    actionCondition,
    className,
    confirmButtonText,
    secondaryBtn,
    modalTitle,
    modalSubTitle,
  } = props;

  if (!visibility) {
    return null;
  }

  return (
    <Modal
      className="action-popup"
      show={visibility}
      onHide={onHide}
      dialogClassName={`${"confirmation_modal height-auto"} ${className}`}
    >
      <Modal.Body>
        <div className="modal-popup">
          {modalTitle && <h2 className="fs-16 fw-500">{modalTitle}</h2>}
          {modalSubTitle && <p>{modalSubTitle}</p>}
          {children && children}
        </div>
      </Modal.Body>
      <div className="divider" />
      <Modal.Footer>
        <div className="align-right">
          <button
            type="button"
            className="btn btn-textIcon btn-sm"
            onClick={onNo}
          >
            {secondaryBtn}
          </button>
          <button
            type="button"
            className={`btn btn-primary ml-2 ${
              actionCondition !== "confirm" ? "confirm-btn" : ""
            }`}
            onClick={onYes}
          >
            {confirmButtonText}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

ConfirmModal.propTypes = {
  modalTitle: PropTypes.string.isRequired,
  modalSubTitle: PropTypes.string,
  visibility: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  children: PropTypes.node,
  onNo: PropTypes.func.isRequired,
  onYes: PropTypes.func.isRequired,
  secondaryBtn: PropTypes.string,
  confirmButtonText: PropTypes.string,
  actionCondition: PropTypes.oneOf(["confirm", "delete"]),
  className: PropTypes.string,
};

ConfirmModal.defaultProps = {
  children: null,
  secondaryBtn: "Cancel",
  confirmButtonText: "Confirm",
  actionCondition: "confirm",
  className: "",
  modalSubTitle: "",
};

export default ConfirmModal;
