import React from "react";

const IconUpTrend = () => {
  return (
    <svg
      width="14"
      height="9"
      viewBox="0 0 14 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.66665 0.5L11.1933 2.02667L7.93998 5.28L5.27331 2.61333L0.333313 7.56L1.27331 8.5L5.27331 4.5L7.93998 7.16667L12.14 2.97333L13.6666 4.5V0.5H9.66665Z"
        fill="#029262"
      />
    </svg>
  );
};

export default IconUpTrend;
