import React from "react";

const IconCameraZeroState = () => {
  return (
    <svg
      width="154"
      height="93"
      viewBox="0 0 154 93"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_29990_180661)">
        <path
          d="M153.999 72.5735C154.003 73.1964 153.759 73.795 153.322 74.2378C152.885 74.6805 152.291 74.9311 151.67 74.9343H49.1887C48.8812 74.9327 48.577 74.8704 48.2936 74.7509C48.0101 74.6314 47.7529 74.457 47.5366 74.2378C47.3203 74.0186 47.1492 73.7588 47.033 73.4733C46.9168 73.1877 46.8578 72.882 46.8594 72.5736V2.36079C46.8562 1.73793 47.0997 1.13928 47.5365 0.696547C47.9733 0.253814 48.5676 0.00325729 49.1886 0H151.67C151.978 0.00160425 152.282 0.0639393 152.565 0.183445C152.849 0.302951 153.106 0.477287 153.322 0.696501C153.539 0.915714 153.71 1.17551 153.826 1.46106C153.942 1.74661 154.001 2.05232 153.999 2.36073V2.36079V72.5735Z"
          fill="#CED4DA"
        />
        <path
          d="M152.791 71.4235C152.791 72.0289 152.551 72.6095 152.124 73.0376C151.697 73.4657 151.118 73.7062 150.515 73.7062H50.4145C49.8109 73.7062 49.2321 73.4657 48.8052 73.0376C48.3784 72.6095 48.1387 72.0289 48.1387 71.4235V3.54247C48.1387 2.93706 48.3784 2.35644 48.8052 1.92835C49.2321 1.50026 49.8109 1.25977 50.4145 1.25977H150.519C151.122 1.26098 151.7 1.50202 152.126 1.92998C152.551 2.35793 152.791 2.93785 152.791 3.54247V71.4235Z"
          fill="white"
        />
        <path
          d="M53.4895 4.20703H147.548C147.907 4.20703 148.25 4.34978 148.504 4.60388C148.757 4.85798 148.899 5.20261 148.899 5.56196V69.1409C148.899 69.5003 148.757 69.8449 148.504 70.099C148.25 70.3531 147.907 70.4959 147.548 70.4959H53.4895C53.1313 70.4959 52.7877 70.3531 52.5343 70.099C52.281 69.8449 52.1387 69.5003 52.1387 69.1409V5.56196C52.1387 5.20261 52.281 4.85798 52.5343 4.60388C52.7877 4.34978 53.1313 4.20703 53.4895 4.20703Z"
          fill="#CED4DA"
        />
        <path
          d="M54.679 5.73242H146.254C146.613 5.73242 146.956 5.87517 147.21 6.12927C147.463 6.38337 147.605 6.728 147.605 7.08735V67.2736C147.605 67.633 147.463 67.9776 147.21 68.2317C146.956 68.4858 146.613 68.6286 146.254 68.6286H54.679C54.3207 68.6286 53.9771 68.4858 53.7238 68.2317C53.4704 67.9776 53.3281 67.633 53.3281 67.2736V7.08735C53.3281 6.728 53.4704 6.38337 53.7238 6.12927C53.9771 5.87517 54.3207 5.73242 54.679 5.73242Z"
          fill="white"
        />
        <path
          d="M119.338 47.5418C118.978 47.5416 118.622 47.4654 118.292 47.3183C118.216 47.2842 118.143 47.2425 118.074 47.194L111.419 42.4951C111.081 42.2568 110.806 41.9406 110.616 41.5732C110.426 41.2057 110.326 40.7978 110.326 40.3838V33.9756C110.326 33.5616 110.426 33.1536 110.616 32.7862C110.806 32.4187 111.081 32.1025 111.419 31.8642L118.074 27.1654C118.143 27.1168 118.216 27.0752 118.292 27.0411C118.684 26.8663 119.114 26.7925 119.541 26.8264C119.969 26.8603 120.382 27.0009 120.741 27.2354C121.101 27.4699 121.397 27.7908 121.601 28.169C121.806 28.5472 121.913 28.9707 121.913 29.401V44.9584C121.913 45.6433 121.642 46.3002 121.159 46.7846C120.676 47.2689 120.021 47.541 119.338 47.541L119.338 47.5418Z"
          fill="#CED4DA"
        />
        <path
          d="M104.443 48.8016H85.8814C82.0944 48.7985 79.0254 46.3432 79.0215 43.3135V31.0458C79.0254 28.016 82.0944 25.5607 85.8814 25.5576H104.492C108.252 25.5612 111.299 27.9989 111.303 31.0071V43.3135C111.299 46.3432 108.23 48.7985 104.443 48.8016Z"
          fill="#CED4DA"
        />
        <path
          d="M137.509 64.7579C140.558 64.7579 143.029 62.2789 143.029 59.2208C143.029 56.1627 140.558 53.6836 137.509 53.6836C134.46 53.6836 131.988 56.1627 131.988 59.2208C131.988 62.2789 134.46 64.7579 137.509 64.7579Z"
          fill="#CED4DA"
        />
        <path
          d="M140.377 59.1212L135.976 56.573C135.959 56.5629 135.939 56.5576 135.919 56.5576C135.899 56.5576 135.879 56.5629 135.862 56.573C135.844 56.583 135.83 56.5975 135.82 56.6149C135.81 56.6323 135.805 56.6521 135.805 56.6722V61.7688C135.805 61.7889 135.81 61.8086 135.82 61.8261C135.83 61.8435 135.844 61.858 135.862 61.868C135.879 61.8781 135.899 61.8834 135.919 61.8834C135.939 61.8834 135.959 61.8781 135.976 61.868L140.377 59.3198C140.394 59.3097 140.408 59.2952 140.418 59.2778C140.428 59.2604 140.434 59.2406 140.434 59.2205C140.434 59.2004 140.428 59.1806 140.418 59.1632C140.408 59.1458 140.394 59.1313 140.377 59.1212L135.976 56.573C135.959 56.5629 135.939 56.5576 135.919 56.5576C135.899 56.5576 135.879 56.5629 135.862 56.573C135.844 56.583 135.83 56.5975 135.82 56.6149C135.81 56.6323 135.805 56.6521 135.805 56.6722V61.7688C135.805 61.7889 135.81 61.8086 135.82 61.8261C135.83 61.8435 135.844 61.858 135.862 61.868C135.879 61.8781 135.899 61.8834 135.919 61.8834C135.939 61.8834 135.959 61.8781 135.976 61.868L140.377 59.3198C140.394 59.3097 140.408 59.2952 140.418 59.2778C140.428 59.2604 140.434 59.2406 140.434 59.2205C140.434 59.2004 140.428 59.1806 140.418 59.1632C140.408 59.1458 140.394 59.1313 140.377 59.1212Z"
          fill="white"
        />
        <path
          d="M130.341 80.9944C130.344 81.6173 130.101 82.2159 129.664 82.6587C129.227 83.1014 128.633 83.3519 128.012 83.3552H25.5305C25.223 83.3536 24.9188 83.2913 24.6354 83.1718C24.3519 83.0523 24.0947 82.8779 23.8784 82.6587C23.6621 82.4395 23.491 82.1797 23.3748 81.8941C23.2586 81.6086 23.1996 81.3029 23.2012 80.9945V10.7817C23.198 10.1588 23.4415 9.56018 23.8783 9.11745C24.3151 8.67471 24.9094 8.42416 25.5304 8.4209H128.012C128.319 8.4225 128.624 8.48484 128.907 8.60434C129.19 8.72385 129.448 8.89819 129.664 9.1174C129.88 9.33661 130.051 9.59641 130.168 9.88196C130.284 10.1675 130.343 10.4732 130.341 10.7816V10.7817V80.9944Z"
          fill="#CED4DA"
        />
        <path
          d="M129.132 79.8444C129.132 80.4498 128.893 81.0304 128.466 81.4585C128.039 81.8866 127.46 82.1271 126.857 82.1271H26.7563C26.1527 82.1271 25.5739 81.8866 25.147 81.4585C24.7202 81.0304 24.4805 80.4498 24.4805 79.8444V11.9634C24.4805 11.358 24.7202 10.7773 25.147 10.3493C25.5739 9.92116 26.1527 9.68066 26.7563 9.68066H126.861C127.464 9.68188 128.042 9.92292 128.467 10.3509C128.893 10.7788 129.132 11.3588 129.132 11.9634V79.8444Z"
          fill="white"
        />
        <path
          d="M29.8294 12.6279H123.888C124.246 12.6279 124.59 12.7707 124.843 13.0248C125.097 13.2789 125.239 13.6235 125.239 13.9829V77.5618C125.239 77.9212 125.097 78.2658 124.843 78.5199C124.59 78.774 124.246 78.9167 123.888 78.9167H29.8294C29.4711 78.9167 29.1275 78.774 28.8742 78.5199C28.6208 78.2658 28.4785 77.9212 28.4785 77.5618V13.9829C28.4785 13.6235 28.6208 13.2789 28.8742 13.0248C29.1275 12.7707 29.4711 12.6279 29.8294 12.6279Z"
          fill="#CED4DA"
        />
        <path
          d="M31.0208 14.1523H122.596C122.954 14.1523 123.298 14.2951 123.551 14.5492C123.805 14.8033 123.947 15.1479 123.947 15.5073V75.6936C123.947 76.0529 123.805 76.3975 123.551 76.6516C123.298 76.9057 122.954 77.0485 122.596 77.0485H31.0208C30.6625 77.0485 30.3189 76.9057 30.0656 76.6516C29.8122 76.3975 29.6699 76.0529 29.6699 75.6936V15.5073C29.6699 15.1479 29.8122 14.8033 30.0656 14.5492C30.3189 14.2951 30.6625 14.1523 31.0208 14.1523Z"
          fill="white"
        />
        <path
          d="M95.6801 55.9628C95.3197 55.9625 94.9633 55.8863 94.6341 55.7392C94.5575 55.7051 94.4844 55.6635 94.416 55.6149L87.7607 50.9161C87.4232 50.6777 87.1477 50.3615 86.9575 49.9941C86.7674 49.6266 86.6681 49.2187 86.668 48.8047V42.3965C86.6681 41.9825 86.7674 41.5745 86.9575 41.2071C87.1477 40.8396 87.4232 40.5234 87.7607 40.2851L94.416 35.5863C94.4844 35.5377 94.5575 35.4961 94.6341 35.462C95.0261 35.2872 95.4555 35.2134 95.8832 35.2473C96.3109 35.2812 96.7233 35.4218 97.0831 35.6563C97.4429 35.8908 97.7385 36.2117 97.9432 36.5899C98.1478 36.9681 98.255 37.3916 98.255 37.8219V53.3793C98.255 54.0643 97.9838 54.7212 97.5009 55.2055C97.018 55.6899 96.363 55.962 95.6801 55.962L95.6801 55.9628Z"
          fill="#CED4DA"
        />
        <path
          d="M80.7852 57.2226H62.2232C58.4362 57.2195 55.3672 54.7641 55.3633 51.7344V39.4667C55.3672 36.4369 58.4362 33.9816 62.2232 33.9785H80.8336C84.5936 33.9821 87.6406 36.4198 87.6451 39.428V51.7344C87.6412 54.7641 84.5722 57.2195 80.7852 57.2226Z"
          fill="#CED4DA"
        />
        <path
          d="M113.851 73.1789C116.899 73.1789 119.371 70.6998 119.371 67.6417C119.371 64.5836 116.899 62.1045 113.851 62.1045C110.802 62.1045 108.33 64.5836 108.33 67.6417C108.33 70.6998 110.802 73.1789 113.851 73.1789Z"
          fill="#CED4DA"
        />
        <path
          d="M116.716 67.5421L112.316 64.9939C112.299 64.9838 112.279 64.9785 112.259 64.9785C112.239 64.9785 112.219 64.9838 112.202 64.9939C112.184 65.0039 112.17 65.0184 112.16 65.0358C112.15 65.0532 112.145 65.073 112.145 65.0931V70.1897C112.145 70.2098 112.15 70.2295 112.16 70.247C112.17 70.2644 112.184 70.2789 112.202 70.2889C112.219 70.299 112.239 70.3043 112.259 70.3043C112.279 70.3043 112.299 70.299 112.316 70.2889L116.716 67.7407C116.734 67.7306 116.748 67.7161 116.758 67.6987C116.768 67.6813 116.774 67.6615 116.774 67.6414C116.774 67.6213 116.768 67.6015 116.758 67.5841C116.748 67.5667 116.734 67.5522 116.716 67.5421L112.316 64.9939C112.299 64.9838 112.279 64.9785 112.259 64.9785C112.239 64.9785 112.219 64.9838 112.202 64.9939C112.184 65.0039 112.17 65.0184 112.16 65.0358C112.15 65.0532 112.145 65.073 112.145 65.0931V70.1897C112.145 70.2098 112.15 70.2295 112.16 70.247C112.17 70.2644 112.184 70.2789 112.202 70.2889C112.219 70.299 112.239 70.3043 112.259 70.3043C112.279 70.3043 112.299 70.299 112.316 70.2889L116.716 67.7407C116.734 67.7306 116.748 67.7161 116.758 67.6987C116.768 67.6813 116.774 67.6615 116.774 67.6414C116.774 67.6213 116.768 67.6015 116.758 67.5841C116.748 67.5667 116.734 67.5522 116.716 67.5421Z"
          fill="white"
        />
        <path
          d="M107.14 90.639C107.143 91.2619 106.9 91.8605 106.463 92.3032C106.026 92.746 105.432 92.9965 104.811 92.9998H2.32929C2.02181 92.9982 1.71765 92.9358 1.43419 92.8163C1.15072 92.6968 0.893502 92.5225 0.67721 92.3033C0.460918 92.0841 0.289791 91.8243 0.173601 91.5387C0.0574102 91.2532 -0.00156807 90.9475 3.20533e-05 90.639V20.4262C-0.00321501 19.8034 0.240357 19.2047 0.677166 18.762C1.11397 18.3192 1.70824 18.0687 2.32923 18.0654H104.811C105.118 18.067 105.422 18.1294 105.706 18.2489C105.989 18.3684 106.246 18.5427 106.463 18.7619C106.679 18.9811 106.85 19.2409 106.966 19.5265C107.083 19.812 107.142 20.1177 107.14 20.4262V20.4262V90.639Z"
          fill="#CED4DA"
        />
        <path
          d="M105.931 89.49C105.931 90.0954 105.691 90.676 105.265 91.1041C104.838 91.5322 104.259 91.7727 103.655 91.7727H3.55514C2.95155 91.7727 2.37268 91.5322 1.94588 91.1041C1.51907 90.676 1.2793 90.0954 1.2793 89.49V21.6089C1.2793 21.0035 1.51907 20.4229 1.94588 19.9948C2.37268 19.5667 2.95155 19.3262 3.55514 19.3262H103.66C104.263 19.3274 104.84 19.5684 105.266 19.9964C105.692 20.4243 105.931 21.0043 105.931 21.6089V89.49Z"
          fill="white"
        />
        <path
          d="M6.62819 22.2725H100.687C101.045 22.2725 101.389 22.4152 101.642 22.6693C101.896 22.9234 102.038 23.268 102.038 23.6274V87.2064C102.038 87.5657 101.896 87.9104 101.642 88.1645C101.389 88.4186 101.045 88.5613 100.687 88.5613H6.62819C6.26993 88.5613 5.92633 88.4186 5.673 88.1645C5.41967 87.9104 5.27734 87.5657 5.27734 87.2064V23.6274C5.27734 23.268 5.41967 22.9234 5.673 22.6693C5.92633 22.4152 6.26993 22.2725 6.62819 22.2725Z"
          fill="#CED4DA"
        />
        <path
          d="M7.8196 23.7979H99.395C99.5724 23.7979 99.7481 23.8329 99.9119 23.901C100.076 23.9691 100.225 24.0689 100.35 24.1947C100.476 24.3205 100.575 24.4699 100.643 24.6343C100.711 24.7987 100.746 24.9748 100.746 25.1528V85.3391C100.746 85.517 100.711 85.6932 100.643 85.8576C100.575 86.022 100.476 86.1713 100.35 86.2971C100.225 86.423 100.076 86.5228 99.9119 86.5909C99.7481 86.6589 99.5724 86.694 99.395 86.694H7.8196C7.46133 86.694 7.11774 86.5512 6.8644 86.2971C6.61107 86.043 6.46875 85.6984 6.46875 85.3391V25.1528C6.46875 24.7934 6.61107 24.4488 6.8644 24.1947C7.11774 23.9406 7.46133 23.7979 7.8196 23.7979Z"
          fill="white"
        />
        <path
          d="M72.479 65.6083C72.1185 65.608 71.7621 65.5319 71.4329 65.3847C71.3563 65.3506 71.2833 65.309 71.2148 65.2604L64.5595 60.5616C64.222 60.3233 63.9466 60.0071 63.7564 59.6396C63.5662 59.2722 63.4669 58.8642 63.4668 58.4502V52.042C63.4669 51.628 63.5662 51.22 63.7564 50.8526C63.9465 50.4852 64.222 50.169 64.5595 49.9306L71.2148 45.2318C71.2833 45.1832 71.3563 45.1416 71.4329 45.1075C71.8249 44.9327 72.2543 44.8589 72.682 44.8928C73.1097 44.9267 73.5222 45.0673 73.8819 45.3018C74.2417 45.5363 74.5373 45.8572 74.742 46.2354C74.9466 46.6136 75.0538 47.0371 75.0539 47.4674V63.0248C75.0539 63.7098 74.7826 64.3667 74.2997 64.851C73.8168 65.3354 73.1619 65.6075 72.4789 65.6075L72.479 65.6083Z"
          fill="#CED4DA"
        />
        <path
          d="M57.584 66.8681H39.022C35.235 66.865 32.166 64.4096 32.1621 61.3799V49.1122C32.166 46.0825 35.235 43.6271 39.022 43.624H57.6324C61.3924 43.6276 64.4394 46.0653 64.4439 49.0735V61.3799C64.44 64.4096 61.371 66.865 57.584 66.8681Z"
          fill="#CED4DA"
        />
        <path
          d="M90.6494 82.8234C93.6983 82.8234 96.1699 80.3443 96.1699 77.2862C96.1699 74.2281 93.6983 71.749 90.6494 71.749C87.6005 71.749 85.1289 74.2281 85.1289 77.2862C85.1289 80.3443 87.6005 82.8234 90.6494 82.8234Z"
          fill="#CED4DA"
        />
        <path
          d="M93.5152 77.1877L89.1148 74.6394C89.0974 74.6293 89.0777 74.624 89.0576 74.624C89.0376 74.624 89.0179 74.6293 89.0005 74.6394C88.9831 74.6494 88.9687 74.6639 88.9587 74.6813C88.9486 74.6988 88.9434 74.7185 88.9434 74.7386V79.8352C88.9434 79.8553 88.9486 79.8751 88.9587 79.8925C88.9687 79.9099 88.9831 79.9244 89.0005 79.9344C89.0179 79.9445 89.0376 79.9498 89.0576 79.9498C89.0777 79.9498 89.0974 79.9445 89.1148 79.9344L93.5152 77.3862C93.5326 77.3761 93.547 77.3616 93.557 77.3442C93.567 77.3268 93.5723 77.307 93.5723 77.2869C93.5723 77.2668 93.567 77.247 93.557 77.2296C93.547 77.2122 93.5326 77.1977 93.5152 77.1877L89.1148 74.6394C89.0974 74.6293 89.0777 74.624 89.0576 74.624C89.0376 74.624 89.0179 74.6293 89.0005 74.6394C88.9831 74.6494 88.9687 74.6639 88.9587 74.6813C88.9486 74.6988 88.9434 74.7185 88.9434 74.7386V79.8352C88.9434 79.8553 88.9486 79.8751 88.9587 79.8925C88.9687 79.9099 88.9831 79.9244 89.0005 79.9345C89.0179 79.9445 89.0376 79.9498 89.0576 79.9498C89.0777 79.9498 89.0974 79.9445 89.1148 79.9344L93.5152 77.3862C93.5326 77.3761 93.547 77.3616 93.557 77.3442C93.5671 77.3268 93.5723 77.307 93.5723 77.2869C93.5723 77.2668 93.5671 77.247 93.557 77.2296C93.547 77.2122 93.5326 77.1977 93.5152 77.1877Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_29990_180661">
          <rect width="154" height="93" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconCameraZeroState;
