import React, { Suspense, useMemo } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import GroupedRoutesTab from "../../../../Common/Components/Molecule/GroupedRoutesTab";
import RecorderListSkeleton from "../../../../Common/Components/SkeletonComponent/MainModulesSkeleton/RecorderListSkeleton";
import { LOCATION, PAGE404 } from "../../../../Constants";
import {
  LocationContainer,
  RegionContainer,
  SubRegionContainer,
} from "../../../Containers/Location";
import { useLocationContext } from "./LocationContext";

const tabListGroup = [
  { key: "locations", name: "Locations", link: LOCATION },
  { key: "cities", name: "Cities", link: `${LOCATION}/cities` },
  { key: "regions", name: "Regions", link: `${LOCATION}/regions` },
];

function LocationsLayout() {
  const { pathname } = useLocation();
  const { currentTabDataCount, setCurrentTabDataCount } = useLocationContext();

  const tabDataCount = useMemo(
    () => [
      { name: "locations", count: currentTabDataCount?.locations || 0 },
      { name: "regions", count: currentTabDataCount?.regions || 0 },
      { name: "cities", count: currentTabDataCount?.cities || 0 },
    ],
    [currentTabDataCount]
  );
  return (
    <GroupedRoutesTab
      currentPath={pathname}
      tabList={tabListGroup}
      tabDataCounts={tabDataCount}
      handleTabClick={() => setCurrentTabDataCount({})}
    >
      <Switch>
        {/* Region list */}
        <Route exact path={`${LOCATION}/regions`}>
          <Suspense fallback={<RecorderListSkeleton hideHeader />}>
            <RegionContainer />
          </Suspense>
        </Route>
        {/* Cities */}
        <Route exact path={`${LOCATION}/cities`}>
          <Suspense fallback={<RecorderListSkeleton hideHeader />}>
            <SubRegionContainer />
          </Suspense>
        </Route>
        {/* Locations */}
        <Route exact path={LOCATION}>
          <Suspense fallback={<RecorderListSkeleton hideHeader />}>
            <LocationContainer />
          </Suspense>
        </Route>
        <Route
          path="*"
          render={() => <Redirect to={{ pathname: PAGE404 }} />}
        />
      </Switch>
    </GroupedRoutesTab>
  );
}

export default LocationsLayout;
