import React from "react";

function IconNoImageFound() {
  return (
    <svg
      width="53"
      height="53"
      viewBox="0 0 53 53"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M48.1169 47.9498L34.9002 34.7331L29.0719 28.9048L11.5002 11.3331L8.4452 8.27814L5.21687 5.0498L2.16187 8.1048L7.16687 13.1315V41.6665C7.16687 44.0498 9.11687 45.9998 11.5002 45.9998H40.0352L45.0402 51.0048L48.1169 47.9498ZM11.5002 41.6665V17.4648L26.3202 32.2848L24.5002 34.5598L20.1669 28.6665L13.6669 37.3331H31.3685L35.7019 41.6665H11.5002ZM17.6319 11.3331L13.2985 6.9998H41.8335C44.2169 6.9998 46.1669 8.94981 46.1669 11.3331V39.8681L41.8335 35.5348V11.3331H17.6319Z"
        fill="#7E7E7E"
      />
    </svg>
  );
}

export default IconNoImageFound;
