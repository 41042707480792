import React from "react";

const IconCameraTwo = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.9995 19.8819C16.4738 19.8819 18.4796 17.8761 18.4796 15.4019C18.4796 12.9276 16.4738 10.9219 13.9995 10.9219C11.5253 10.9219 9.51953 12.9276 9.51953 15.4019C9.51953 17.8761 11.5253 19.8819 13.9995 19.8819Z"
        fill="#FF7E17"
      />
      <path
        d="M25.2 4.19846H20.762L18.2 1.39844H9.79997L7.23797 4.19846H2.80002C1.25298 4.19846 0 5.45143 0 6.99848V23.7985C0 25.3454 1.25298 26.5985 2.80002 26.5985H25.2C26.747 26.5985 28 25.3455 28 23.7985V6.99848C28 5.45143 26.747 4.19846 25.2 4.19846ZM14 22.3984C10.136 22.3984 6.99995 19.2624 6.99995 15.3984C6.99995 11.5344 10.136 8.39845 14 8.39845C17.864 8.39845 21 11.5345 21 15.3985C21 19.2625 17.864 22.3984 14 22.3984Z"
        fill="#FF7E17"
      />
    </svg>
  );
};

export default IconCameraTwo;
