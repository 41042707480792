import React from "react";

function IconVs() {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.5" width="24" height="24" rx="2" fill="white" />
      <path
        d="M4.72434 7.31792L7.18528 15.0537H7.27974L9.74565 7.31792H12.132L8.62207 17.4997H5.84792L2.33301 7.31792H4.72434Z"
        fill="#545454"
      />
      <path
        d="M18.8809 10.2462C18.8412 9.84514 18.6705 9.53359 18.3689 9.31152C18.0673 9.08946 17.6579 8.97843 17.1409 8.97843C16.7896 8.97843 16.4929 9.02814 16.251 9.12757C16.009 9.22369 15.8234 9.35793 15.6942 9.53027C15.5682 9.70262 15.5052 9.89817 15.5052 10.1169C15.4986 10.2992 15.5367 10.4583 15.6196 10.5942C15.7058 10.7301 15.8234 10.8477 15.9726 10.9472C16.1217 11.0433 16.2941 11.1278 16.4896 11.2007C16.6852 11.2703 16.894 11.33 17.116 11.3797L18.0308 11.5985C18.4749 11.6979 18.8826 11.8305 19.2538 11.9962C19.625 12.1619 19.9465 12.3657 20.2183 12.6077C20.4901 12.8496 20.7006 13.1347 20.8497 13.4628C21.0022 13.7909 21.08 14.1671 21.0834 14.5914C21.08 15.2145 20.921 15.7547 20.6061 16.2121C20.2945 16.6662 19.8438 17.0191 19.2538 17.271C18.6672 17.5196 17.9595 17.6439 17.1309 17.6439C16.309 17.6439 15.5931 17.518 14.9832 17.2661C14.3767 17.0142 13.9027 16.6413 13.5613 16.1475C13.2233 15.6503 13.046 15.0355 13.0294 14.303H15.1125C15.1357 14.6444 15.2335 14.9294 15.4058 15.1581C15.5815 15.3835 15.8151 15.5542 16.1068 15.6702C16.4018 15.7829 16.7349 15.8392 17.1061 15.8392C17.4707 15.8392 17.7872 15.7862 18.0557 15.6801C18.3274 15.5741 18.5379 15.4266 18.6871 15.2377C18.8362 15.0487 18.9108 14.8316 18.9108 14.5864C18.9108 14.3577 18.8428 14.1655 18.7069 14.0097C18.5744 13.8539 18.3788 13.7213 18.1203 13.6119C17.8651 13.5026 17.5519 13.4031 17.1807 13.3137L16.072 13.0352C15.2136 12.8264 14.5358 12.5 14.0386 12.0558C13.5415 11.6117 13.2945 11.0135 13.2979 10.2611C13.2945 9.64462 13.4586 9.10603 13.79 8.64533C14.1248 8.18463 14.5838 7.82502 15.1672 7.5665C15.7505 7.30797 16.4134 7.17871 17.1558 7.17871C17.9115 7.17871 18.5711 7.30797 19.1345 7.5665C19.7013 7.82502 20.1421 8.18463 20.4569 8.64533C20.7718 9.10603 20.9342 9.63965 20.9442 10.2462H18.8809Z"
        fill="#545454"
      />
    </svg>
  );
}

export default IconVs;
