import React from "react";

const IconOrder = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_38902_239938)">
        <path
          d="M11.9498 7.95L10.5358 9.364L7.9998 6.828V20H5.9998V6.828L3.4648 9.364L2.0498 7.95L6.9998 3L11.9498 7.95ZM21.9498 16.05L16.9998 21L12.0498 16.05L13.4638 14.636L16.0008 17.172L15.9998 4H17.9998V17.172L20.5358 14.636L21.9498 16.05Z"
          fill="#3766E8"
        />
      </g>
      <defs>
        <clipPath id="clip0_38902_239938">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>

    // <svg
    //   width="12"
    //   height="13"
    //   viewBox="0 0 12 13"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    // >
    //   <g clip-path="url(#clip0_38553_3344)">
    //     <path
    //       d="M8 9.48938V5.98438H7V9.48938H5.5L7.5 11.4844L9.5 9.48938H8ZM4.5 2.48438L2.5 4.47937H4V7.98438H5V4.47937H6.5L4.5 2.48438Z"
    //       fill="#3766E8"
    //     />
    //   </g>
    //   <defs>
    //     <clipPath id="clip0_38553_3344">
    //       <rect
    //         width="12"
    //         height="12"
    //         fill="white"
    //         transform="translate(0 0.984375)"
    //       />
    //     </clipPath>
    //   </defs>
    // </svg>
  );
};

export default IconOrder;
