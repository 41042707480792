import React from "react";

const IconOccupancyBased = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 14 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0002 5.00016C10.9202 5.00016 11.6602 4.2535 11.6602 3.3335C11.6602 2.4135 10.9202 1.66683 10.0002 1.66683C9.08016 1.66683 8.3335 2.4135 8.3335 3.3335C8.3335 4.2535 9.08016 5.00016 10.0002 5.00016ZM5.00016 4.3335C6.10683 4.3335 6.9935 3.44016 6.9935 2.3335C6.9935 1.22683 6.10683 0.333496 5.00016 0.333496C3.8935 0.333496 3.00016 1.22683 3.00016 2.3335C3.00016 3.44016 3.8935 4.3335 5.00016 4.3335ZM10.0002 6.3335C8.78016 6.3335 6.3335 6.94683 6.3335 8.16683V9.66683H13.6668V8.16683C13.6668 6.94683 11.2202 6.3335 10.0002 6.3335ZM5.00016 5.66683C3.44683 5.66683 0.333496 6.44683 0.333496 8.00016V9.66683H5.00016V8.16683C5.00016 7.60016 5.22016 6.60683 6.58016 5.8535C6.00016 5.7335 5.44016 5.66683 5.00016 5.66683Z"
        fill="#3766E8"
      />
    </svg>
  );
};

export default IconOccupancyBased;
