import React from "react";

const IconDefaultMode = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.6364 5C15.8775 5 16.1087 5.09219 16.2792 5.25628C16.4497 5.42038 16.5455 5.64294 16.5455 5.875V9.55L21.2845 6.35625C21.3527 6.31027 21.4326 6.28319 21.5157 6.27794C21.5987 6.27269 21.6817 6.28949 21.7555 6.3265C21.8293 6.3635 21.8911 6.4193 21.9342 6.48781C21.9773 6.55632 22.0001 6.6349 22 6.715V17.285C22.0001 17.3651 21.9773 17.4437 21.9342 17.5122C21.8911 17.5807 21.8293 17.6365 21.7555 17.6735C21.6817 17.7105 21.5987 17.7273 21.5157 17.7221C21.4326 17.7168 21.3527 17.6897 21.2845 17.6438L16.5455 14.45V18.125C16.5455 18.3571 16.4497 18.5796 16.2792 18.7437C16.1087 18.9078 15.8775 19 15.6364 19H2.90909C2.66799 19 2.43675 18.9078 2.26627 18.7437C2.09578 18.5796 2 18.3571 2 18.125V5.875C2 5.64294 2.09578 5.42038 2.26627 5.25628C2.43675 5.09219 2.66799 5 2.90909 5H15.6364ZM14.7273 6.75H3.81818V17.25H14.7273V6.75ZM20.1818 9.235L16.5455 11.685V12.3132L20.1818 14.7633V9.235Z"
        fill="#545454"
      />
      <path
        d="M5.63636 8.675C5.63636 8.57835 5.71777 8.5 5.81818 8.5H10C10.1004 8.5 10.1818 8.57835 10.1818 8.675V10.075C10.1818 10.1716 10.1004 10.25 10 10.25H5.81818C5.71777 10.25 5.63636 10.1716 5.63636 10.075V8.675Z"
        fill="#545454"
      />
    </svg>
  );
};

export default IconDefaultMode;
