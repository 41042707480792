import React, { Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import RecorderListSkeleton from "../../Common/Components/SkeletonComponent/MainModulesSkeleton/RecorderListSkeleton";
import SettingsModuleSkeleton from "../../Common/Components/SkeletonComponent/MainModulesSkeleton/SettingsModuleSkeleton";
import SettingDataOutApiSkeleton from "../../Common/Components/SkeletonComponent/MainModulesSkeleton/SettingsModuleSkeleton/SettingDataOutApiSkeleton";
import SettingsAdvanceSettingsSkeletons from "../../Common/Components/SkeletonComponent/MainModulesSkeleton/SettingsModuleSkeleton/SettingsAdvanceSettingsSkeletons";
import SettingsCompanyDetailsSkeleton from "../../Common/Components/SkeletonComponent/MainModulesSkeleton/SettingsModuleSkeleton/SettingsCompanyDetailsSkeleton";
import SettingsIntegrationModuleSkeleton from "../../Common/Components/SkeletonComponent/MainModulesSkeleton/SettingsModuleSkeleton/SettingsIntegrationModuleSkeleton";
import SettingJoltSkeleton from "../../Common/Components/SkeletonComponent/MainModulesSkeleton/SettingsModuleSkeleton/SettingsJoltSkeleton";
import SettingsModuleWrapperSkeleton from "../../Common/Components/SkeletonComponent/MainModulesSkeleton/SettingsModuleSkeleton/SettingsModuleWrapperSkeleton";
import SettingsOpenEyeSkeleton from "../../Common/Components/SkeletonComponent/MainModulesSkeleton/SettingsModuleSkeleton/SettingsOpeneyeSkeleton";
import SettingsPlansModuleSkeleton from "../../Common/Components/SkeletonComponent/MainModulesSkeleton/SettingsModuleSkeleton/SettingsPlansModuleSkeleton";
import SettingSSOSkeleton from "../../Common/Components/SkeletonComponent/MainModulesSkeleton/SettingsModuleSkeleton/SettingsSSOSkeleton";
import {
  ADVANCED_SETTINGS,
  CAMERA_INTEGRATION,
  COMPANY_DETAILS,
  DATA_OUT_API,
  EMPLOYEE,
  JOLT,
  LOCATION,
  OPENEYE,
  PAGE404,
  SCHEDULE,
  SSO,
  STATION,
  SUBSCRIPTION_DETAILS,
  TAT,
  ZONE,
} from "../../Constants";
import LocationsLayout from "../Components/Common/LocationsLayout";
import LocationContextProvider from "../Components/Common/LocationsLayout/LocationContext";
import SchedulesLayout from "../Components/Common/SchedulesLayout";
import SettingsLayout from "../Components/Common/SettingsLayout";
import { SettingsLayoutProvider } from "../Contexts/SettingLayoutContexts";
import AdvancedSettingsContainer from "./AdvanceSettings";
import CompanyComponentContainer from "./Company";
import { EmployeeContainer } from "./Employee";
import {
  DataOutApiContainer,
  IntegrationContainer,
  JoltIntegrationContainer,
  OpenEyeIntegrationContainer,
  SsoIntegrationContainer,
} from "./Integration";
import StationComponentContainer from "./Station";
import SubscriptionContainer from "./Subscriptions";
import TatComponentContainer from "./Tat";
import ZoneContainer from "./Zone";

function SettingsLayoutContainer() {
  return (
    <SettingsLayoutProvider>
      <SettingsLayout>
        <Switch>
          {/* User list */}
          <Route exact path={EMPLOYEE}>
            <Suspense fallback={<RecorderListSkeleton hideHeader />}>
              <EmployeeContainer />
            </Suspense>
          </Route>
          {/* Advance */}
          <Route exact path={ADVANCED_SETTINGS}>
            <Suspense fallback={<SettingsAdvanceSettingsSkeletons />}>
              <AdvancedSettingsContainer />
            </Suspense>
          </Route>
          {/* Tat */}
          <Route exact path={TAT}>
            <Suspense fallback={<SettingsModuleWrapperSkeleton />}>
              <TatComponentContainer />
            </Suspense>
          </Route>
          {/* Stations */}
          <Route exact path={STATION}>
            <Suspense fallback={<SettingsModuleSkeleton />}>
              <StationComponentContainer />
            </Suspense>
          </Route>
          {/* Zones */}
          <Route exact path={ZONE}>
            <Suspense fallback={<SettingsModuleSkeleton />}>
              <ZoneContainer />
            </Suspense>
          </Route>
          {/* Integration */}
          <Route exact path={OPENEYE}>
            <Suspense fallback={<SettingsOpenEyeSkeleton />}>
              <OpenEyeIntegrationContainer />
            </Suspense>
          </Route>

          <Route exact path={SSO}>
            <Suspense fallback={<SettingSSOSkeleton />}>
              <SsoIntegrationContainer />
            </Suspense>
          </Route>

          <Route exact path={DATA_OUT_API}>
            <Suspense fallback={<SettingDataOutApiSkeleton />}>
              <DataOutApiContainer />
            </Suspense>
          </Route>

          <Route exact path={JOLT}>
            <Suspense fallback={<SettingJoltSkeleton />}>
              <JoltIntegrationContainer />
            </Suspense>
          </Route>
          <Route exact path={CAMERA_INTEGRATION}>
            <Suspense exact fallback={<SettingsIntegrationModuleSkeleton />}>
              <IntegrationContainer />
            </Suspense>
          </Route>
          {/* Subscription */}
          <Route exact path={SUBSCRIPTION_DETAILS}>
            <Suspense fallback={<SettingsPlansModuleSkeleton />}>
              <SubscriptionContainer />
            </Suspense>
          </Route>
          {/* Company details */}
          <Route exact path={COMPANY_DETAILS}>
            <Suspense fallback={<SettingsCompanyDetailsSkeleton />}>
              <CompanyComponentContainer />
            </Suspense>
          </Route>
          {/* Location groupe */}
          <Route path={LOCATION}>
            <LocationContextProvider>
              <LocationsLayout />
            </LocationContextProvider>
          </Route>
          {/* Schedule group */}
          <Route path={SCHEDULE}>
            <SchedulesLayout />
          </Route>
          <Route
            path="*"
            render={() => <Redirect to={{ pathname: PAGE404 }} />}
          />
        </Switch>
      </SettingsLayout>
    </SettingsLayoutProvider>
  );
}

export default SettingsLayoutContainer;
