import React from "react";

function IconError() {
  return (
    <svg
      width="20"
      height="24"
      viewBox="0 0 20 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.9001 17.802L10.4001 5.80198C10.3001 5.50198 10.0001 5.40198 9.80006 5.60198C9.70006 5.60198 9.60005 5.70198 9.60005 5.80198L3.10005 17.802C3.00005 18.002 3.00005 18.102 3.10005 18.302C3.20005 18.402 3.30005 18.502 3.50005 18.502H16.5001C16.7001 18.502 16.8001 18.402 16.9001 18.302C17.0001 18.102 17.0001 17.902 16.9001 17.802ZM9.40005 10.002H10.5001V14.002H9.40005V10.002ZM10.0001 16.702C9.60006 16.702 9.20006 16.402 9.20006 15.902C9.20006 15.402 9.50006 15.102 10.0001 15.102C10.4001 15.102 10.8001 15.402 10.8001 15.902C10.8001 16.402 10.4001 16.702 10.0001 16.702Z"
        fill="#DC3545"
      />
    </svg>
  );
}

export default IconError;
