import React, { lazy } from "react";
import PageContainer from "../../Common/Containers/_layouts/PageContainer";
import { PAGE_TITLES } from "../../Services/checkWobotDomain";

const ComparativeAnalyticsComponent = lazy(() =>
  import("../Components/ComparativeAnalyticsComponent")
);
function ComparativeAnalyticsContainer() {
  return (
    <PageContainer
      initialPageTitle={PAGE_TITLES.comparativeAnalytics}
      meta="Add a user you wish to collaborate with, and optimize your work-load."
      withLayout={false}
    >
      <ComparativeAnalyticsComponent />
    </PageContainer>
  );
}

export default ComparativeAnalyticsContainer;
