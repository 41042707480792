// MyComponent.js
import React from "react";
import Skeleton from "react-loading-skeleton";
import styles from "./Styles.module.scss";

function SettingsCompanyDetailsSkeleton() {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1>
          <Skeleton width={220} height={30} />
        </h1>
        <p>
          <Skeleton width={400} height={20} />
        </p>
      </div>

      <div className={styles.formContainer}>
        <div className={styles.formRow}>
          <div className={styles.formColumn}>
            <div className={styles.formLabel}>
              <Skeleton width="40%" height={25} />
            </div>
            <div className={styles.formInput}>
              <Skeleton width="100%" height={35} />
            </div>
          </div>
          <div className={styles.formColumn}>
            <div className={styles.formLabel}>
              <Skeleton width="20%" height={25} />
            </div>
            <div className={`${styles.formInput} d-flex align-items-center`}>
              <Skeleton width={120} height={93} className="mr-4" />
              <Skeleton width={250} height={25} />
            </div>
          </div>
          <div className={styles.formColumn}>
            <div className={styles.formLabel}>
              <Skeleton width="20%" height={25} />
            </div>
            <div className={styles.formInput}>
              <Skeleton width="100%" height={35} />
            </div>
          </div>
          <div className={styles.formColumn}>
            <div className={styles.formLabel}>
              <Skeleton width="30%" height={25} />
            </div>
            <div className={styles.formInput}>
              <Skeleton width="100%" height={35} />
            </div>
          </div>
          <div className={styles.formColumn}>
            <div className={styles.formLabel}>
              <Skeleton width="40%" height={25} />
            </div>
            <div className={`${styles.formInput}`}>
              <Skeleton width={70} height={35} count={4} className="mr-2" />
            </div>
          </div>
        </div>
        <div
          className={`${styles.formRow} d-flex flex-row justify-content-end mt-3`}
        >
          <Skeleton width={135} height={45} />
        </div>
      </div>
    </div>
  );
}

export default SettingsCompanyDetailsSkeleton;
