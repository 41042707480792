import React, { lazy } from "react";
import PageContainer from "../../../Common/Containers/_layouts/PageContainer";
import { settings } from "../../../Constants/MetaDetails";
import { PAGE_TITLES } from "../../../Services/checkWobotDomain";

const SubscriptionDetailsComponent = lazy(() =>
  import("../../Components/SubscriptionComponent/Details")
);

function SubscriptionContainer() {
  return (
    <PageContainer
      initialPageTitle={PAGE_TITLES.subscription}
      meta={settings?.usageAndPlans?.subtitle}
      withLayout={false}
    >
      <SubscriptionDetailsComponent />
    </PageContainer>
  );
}

export default SubscriptionContainer;
