import React from "react";

const IconEdit = ({ size = "16", color = "black", filled = true }) => {
  return filled ? (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 18.9993V24H5.00069L19.756 9.24459L14.7553 4.2439L0 18.9993Z"
        fill={color}
      />
      <path
        d="M23.6099 3.5038L20.4961 0.390054C19.976 -0.130018 19.1292 -0.130018 18.6092 0.390054L16.1688 2.83039L21.1695 7.83108L23.6099 5.39074C24.13 4.87067 24.13 4.02387 23.6099 3.5038Z"
        fill={color}
      />
    </svg>
  ) : (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_37770_225317)">
        <path
          d="M12.527 7.26407L11.4665 6.20357L4.48096 13.1891V14.2496H5.54146L12.527 7.26407ZM13.5875 6.20357L14.648 5.14307L13.5875 4.08257L12.527 5.14307L13.5875 6.20357ZM6.16246 15.7496H2.98096V12.5673L13.0572 2.49107C13.1979 2.35047 13.3886 2.27148 13.5875 2.27148C13.7863 2.27148 13.9771 2.35047 14.1177 2.49107L16.2395 4.61282C16.3801 4.75347 16.459 4.9442 16.459 5.14307C16.459 5.34195 16.3801 5.53268 16.2395 5.67332L6.16321 15.7496H6.16246Z"
          fill="#545454"
        />
      </g>
      <defs>
        <clipPath id="clip0_37770_225317">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(0.730957)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconEdit;
