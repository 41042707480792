import React from "react";

function IconRegionAndCity() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_40484_55746)">
        <path
          d="M4.15665 4.30167C3.30667 5.18387 2.78631 6.33178 2.68303 7.55245C2.57974 8.77313 2.89983 9.99215 3.58948 11.0046C4.27914 12.0171 5.29632 12.7613 6.47005 13.1121C7.64378 13.4629 8.90251 13.399 10.0346 12.931C10.1113 12.431 9.94331 11.951 9.87465 11.783C9.72131 11.4097 9.21598 10.7717 8.37265 9.88701C8.14731 9.65034 8.16198 9.46834 8.24265 8.92901L8.25131 8.86834C8.30598 8.49901 8.39798 8.28034 9.64131 8.08301C10.2733 7.98301 10.4393 8.23501 10.6693 8.58501L10.7466 8.69967C10.9653 9.01967 11.1273 9.09301 11.372 9.20367C11.482 9.25367 11.6186 9.31701 11.802 9.42034C12.2366 9.66901 12.2366 9.94967 12.2366 10.5643V10.6343C12.2366 10.895 12.2113 11.1243 12.1713 11.3237C12.6829 10.6816 13.0394 9.93016 13.213 9.1278C13.3867 8.32544 13.3728 7.49382 13.1725 6.6977C12.9722 5.90157 12.5909 5.16241 12.0582 4.53777C11.5255 3.91312 10.8558 3.41985 10.1013 3.09634C9.73265 3.34501 9.22665 3.69767 9.04998 3.93967C8.95998 4.06301 8.83198 4.69434 8.41665 4.74634C8.30865 4.75967 8.16265 4.75034 8.00798 4.74034C7.59331 4.71367 7.02665 4.67701 6.84531 5.16967C6.72998 5.48167 6.70998 6.32967 7.08265 6.76967C7.14265 6.83967 7.15398 6.96967 7.11331 7.11567C7.05998 7.30701 6.95265 7.42367 6.91865 7.44767C6.85465 7.41034 6.72665 7.26167 6.63931 7.16101C6.43065 6.91767 6.16931 6.61434 5.83198 6.52101C5.70931 6.48701 5.57465 6.45901 5.44331 6.43101C5.07731 6.35434 4.66331 6.26701 4.56665 6.06167C4.49598 5.91101 4.49665 5.70367 4.49665 5.48501C4.49665 5.20701 4.49665 4.89301 4.36065 4.58834C4.31337 4.47961 4.24388 4.38197 4.15665 4.30167ZM7.99998 14.6663C4.31798 14.6663 1.33331 11.6817 1.33331 7.99967C1.33331 4.31767 4.31798 1.33301 7.99998 1.33301C11.682 1.33301 14.6666 4.31767 14.6666 7.99967C14.6666 11.6817 11.682 14.6663 7.99998 14.6663Z"
          fill="#545454"
        />
      </g>
    </svg>
  );
}

export default IconRegionAndCity;
