import React from "react";

const IconUserCheck = () => {
  return (
    <svg
      width="16"
      height="19"
      viewBox="0 0 16 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.66634 11.8754V13.617C7.91211 13.3504 7.1049 13.2686 6.31251 13.3785C5.52012 13.4885 4.76567 13.7869 4.11253 14.2489C3.45939 14.7108 2.92663 15.3227 2.55898 16.0332C2.19132 16.7437 1.99952 17.532 1.99967 18.332L0.333008 18.3312C0.332749 17.3136 0.565442 16.3095 1.01326 15.3957C1.46107 14.4819 2.11212 13.6828 2.91652 13.0596C3.72092 12.4363 4.65732 12.0055 5.65397 11.8001C6.65062 11.5947 7.68106 11.6201 8.66634 11.8745V11.8754ZM6.99967 10.832C4.23717 10.832 1.99967 8.59453 1.99967 5.83203C1.99967 3.06953 4.23717 0.832031 6.99967 0.832031C9.76217 0.832031 11.9997 3.06953 11.9997 5.83203C11.9997 8.59453 9.76217 10.832 6.99967 10.832ZM6.99967 9.16536C8.84134 9.16536 10.333 7.6737 10.333 5.83203C10.333 3.99036 8.84134 2.4987 6.99967 2.4987C5.15801 2.4987 3.66634 3.99036 3.66634 5.83203C3.66634 7.6737 5.15801 9.16536 6.99967 9.16536ZM11.8272 16.5937L14.773 13.6479L15.9522 14.8262L11.8272 18.9512L8.88051 16.0045L10.0597 14.8262L11.8263 16.5937H11.8272Z"
        fill="white"
      />
    </svg>
  );
};

export default IconUserCheck;
