import React from "react";

const IconUnmute = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 12C18 9.64668 16.64 7.6133 14.6667 6.6333V17.3733C16.64 16.3867 18 14.3533 18 12Z"
        fill="#545454"
      />
      <path
        d="M0 7.99994V15.9999H5.33331L12 22.6666V1.33325L5.33331 7.99994H0Z"
        fill="#545454"
      />
      <path
        d="M14.6667 0.306641V3.05995C18.52 4.20664 21.3333 7.77327 21.3333 12C21.3333 16.2266 18.52 19.7933 14.6667 20.94V23.6933C20.0067 22.48 24 17.7133 24 12C24 6.28664 20.0067 1.51995 14.6667 0.306641Z"
        fill="#545454"
      />
    </svg>
  );
};

export default IconUnmute;
