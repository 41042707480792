import React from "react";

const IconProcessBased = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_46885_36532)">
        <path
          d="M9.44683 9.14016L10.3802 7.52683C10.4402 7.42683 10.4135 7.30016 10.3268 7.22683L9.34017 6.4535C9.36017 6.30683 9.3735 6.1535 9.3735 6.00016C9.3735 5.84683 9.36017 5.6935 9.34017 5.54016L10.3268 4.76683C10.4135 4.6935 10.4402 4.56683 10.3802 4.46683L9.44683 2.8535C9.38683 2.7535 9.26683 2.7135 9.16017 2.7535L8.00017 3.22016C7.76017 3.0335 7.50017 2.88016 7.2135 2.76016L7.04017 1.52683C7.02017 1.42016 6.92017 1.3335 6.80683 1.3335H4.94017C4.82683 1.3335 4.72683 1.42016 4.70683 1.5335L4.5335 2.76683C4.2535 2.88683 3.98683 3.04016 3.74683 3.22683L2.58683 2.76016C2.48017 2.72016 2.36016 2.76016 2.30017 2.86016L1.36683 4.4735C1.30683 4.5735 1.3335 4.70016 1.42017 4.7735L2.40683 5.54683C2.38683 5.6935 2.3735 5.84683 2.3735 6.00016C2.3735 6.1535 2.38683 6.30683 2.40683 6.46016L1.42017 7.2335C1.3335 7.30683 1.30683 7.4335 1.36683 7.5335L2.30017 9.14683C2.36016 9.24683 2.48017 9.28683 2.58683 9.24683L3.74683 8.78016C3.98683 8.96683 4.24683 9.12016 4.5335 9.24016L4.70683 10.4735C4.72683 10.5802 4.82683 10.6668 4.94017 10.6668H6.80683C6.92017 10.6668 7.02017 10.5802 7.04017 10.4668L7.2135 9.2335C7.4935 9.1135 7.76017 8.96016 8.00017 8.7735L9.16017 9.24016C9.26683 9.28016 9.38683 9.24016 9.44683 9.14016ZM5.8735 7.3335C5.14017 7.3335 4.54016 6.7335 4.54016 6.00016C4.54016 5.26683 5.14017 4.66683 5.8735 4.66683C6.60683 4.66683 7.20683 5.26683 7.20683 6.00016C7.20683 6.7335 6.60683 7.3335 5.8735 7.3335Z"
          fill="#3766E8"
        />
        <path
          d="M14.6135 12.447L13.9735 11.9537C13.9868 11.8603 14.0002 11.7603 14.0002 11.6603C14.0002 11.5603 13.9935 11.4603 13.9735 11.367L14.6068 10.8737C14.6602 10.827 14.6802 10.747 14.6402 10.6803L14.0402 9.64699C14.0068 9.58033 13.9268 9.56033 13.8535 9.58033L13.1135 9.88033C12.9602 9.76033 12.7935 9.66033 12.6068 9.58699L12.4935 8.80033C12.4868 8.72033 12.4202 8.66699 12.3535 8.66699H11.1602C11.0868 8.66699 11.0202 8.72033 11.0135 8.79366L10.9002 9.58033C10.7202 9.66033 10.5468 9.75366 10.3935 9.87366L9.65349 9.57366C9.58682 9.54699 9.50682 9.57366 9.46682 9.64033L8.86682 10.6737C8.83349 10.7403 8.84015 10.8203 8.90015 10.867L9.53349 11.3603C9.52015 11.4537 9.51349 11.5537 9.51349 11.6537C9.51349 11.7537 9.52015 11.8537 9.53349 11.947L8.90015 12.4403C8.84682 12.487 8.82682 12.567 8.86682 12.6337L9.46682 13.667C9.50015 13.7337 9.58015 13.7537 9.65349 13.7337L10.3935 13.4337C10.5468 13.5537 10.7135 13.6537 10.9002 13.727L11.0135 14.5137C11.0268 14.587 11.0868 14.6403 11.1602 14.6403H12.3535C12.4268 14.6403 12.4935 14.587 12.5002 14.5137L12.6135 13.727C12.7935 13.647 12.9668 13.5537 13.1135 13.4337L13.8602 13.7337C13.9268 13.7603 14.0068 13.7337 14.0468 13.667L14.6468 12.6337C14.6868 12.5737 14.6668 12.4937 14.6135 12.447ZM11.7535 12.5537C11.2602 12.5537 10.8535 12.1537 10.8535 11.6537C10.8535 11.1537 11.2535 10.7537 11.7535 10.7537C12.2535 10.7537 12.6535 11.1537 12.6535 11.6537C12.6535 12.1537 12.2468 12.5537 11.7535 12.5537Z"
          fill="#3766E8"
        />
      </g>
      <defs>
        <clipPath id="clip0_46885_36532">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconProcessBased;
