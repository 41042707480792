import React, { lazy } from "react";
import PageContainer from "../../../Common/Containers/_layouts/PageContainer";
import { settings } from "../../../Constants/MetaDetails";
import { PAGE_TITLES } from "../../../Services/checkWobotDomain";
import { HeatmapProvider } from "../../Components/LocationComponent/LocationHeatMapComponent/Context/HeatmapContext";

const LocationComponent = lazy(() => import("../../Components/LocationComponent"));
const LocationDetailsComponent = lazy(() =>
  import("../../Components/LocationComponent/LocationDetailsComponent")
);
const LocationHeatmapComponent = lazy(() =>
  import("../../Components/LocationComponent/LocationHeatMapComponent")
);
const RegionComponent = lazy(() => import("../../Components/RegionComponent"));
const SubRegionComponent = lazy(() => import("../../Components/SubRegionComponent"));

function LocationContainer() {
  return (
    <PageContainer
      initialPageTitle={PAGE_TITLES.location}
      meta={settings?.locations?.subtitle}
      withLayout={false}
    >
      <LocationComponent />
    </PageContainer>
  );
}

function LocationDetailsContainer() {
  return (
    <PageContainer
      initialPageTitle="Location Details"
      meta={settings?.locations.locationDetails.subtitle}
      withLayout={false}
    >
      <LocationDetailsComponent />
    </PageContainer>
  );
}

function RegionContainer() {
  return (
    <PageContainer
      initialPageTitle={PAGE_TITLES.region}
      meta={settings?.regions?.subtitle}
      withLayout={false}
    >
      <RegionComponent />
    </PageContainer>
  );
}

function SubRegionContainer() {
  return (
    <PageContainer
      initialPageTitle={PAGE_TITLES.cities}
      meta={settings?.cities?.subtitle}
      withLayout={false}
    >
      <SubRegionComponent />
    </PageContainer>
  );
}
function LocationHeatmapMappingContainer() {
  return (
    <PageContainer initialPageTitle={PAGE_TITLES.locationHeatMap} withLayout={false}>
      <HeatmapProvider>
        <LocationHeatmapComponent />
      </HeatmapProvider>
    </PageContainer>
  );
}

export {
  LocationContainer,
  LocationDetailsContainer,
  RegionContainer,
  SubRegionContainer,
  LocationHeatmapMappingContainer,
};
