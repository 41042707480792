import { ADD_REDIRECT_URL, CLEAR_REDIRECT_URL_AFTER_LOGIN } from "../constants";

const initialState = null;

export default function redirectUrlAfterLoginReducer(
  state = initialState,
  action = {}
) {
  switch (action.type) {
    case ADD_REDIRECT_URL:
      return action.value;

    case CLEAR_REDIRECT_URL_AFTER_LOGIN:
      return null;

    default:
      return state;
  }
}
