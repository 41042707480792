import PropTypes from "prop-types";
import React from "react";

function IconCameraTableList({ color }) {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 0H12V1.33333H0V0ZM0 10H12V11.3333H0V10ZM0 6.66667H12V8H0V6.66667ZM0 3.33333H12V4.66667H0V3.33333Z"
        fill={color}
      />
    </svg>
  );
}

IconCameraTableList.propTypes = {
  color: PropTypes.string,
};

IconCameraTableList.defaultProps = {
  color: "#3766E8",
};

export default IconCameraTableList;
