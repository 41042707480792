import React from "react";

import Skeleton from "react-loading-skeleton";
import Styles from "./StyleVerificationModalSkeleton.module.scss";
const VerificationModalSkeleton = () => {
  return (
    <div className={`row ${Styles.max_width}`}>
      {Array(2)
        .fill()
        .map((e, index) => (
          <div className={"col-xl-6 col-lg-6 col-md-6"} key={index}>
            <div className={`container ${Styles.block_column_wrapper}`}>
              <div className={`${Styles.block_column_flex}`}>
                {/* Container Item */}
                <div className={`${Styles.column_container}`}>
                  <div className={`${Styles.header_block}`}>
                    <h6 className={`mb-0`}>
                      <Skeleton
                        height={40}
                        width={"100%"}
                        style={{ marginBottom: "15px" }}
                      />
                    </h6>
                  </div>
                </div>
              </div>
              <div>
                <Skeleton
                  count={1}
                  width={"100%"}
                  height={140}
                  style={{ marginBottom: "15px" }}
                />
              </div>

              <div>
                <Skeleton
                  count={1}
                  width={"100%"}
                  height={140}
                  style={{ marginBottom: "15px" }}
                />
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default VerificationModalSkeleton;
