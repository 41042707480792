import React from "react";

const IconAnalyticsBorder = () => {
  return (
    <svg
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.999674 0H12.9997C13.1765 0 13.3461 0.0702379 13.4711 0.195262C13.5961 0.320286 13.6663 0.489856 13.6663 0.666667V11.3333C13.6663 11.5101 13.5961 11.6797 13.4711 11.8047C13.3461 11.9298 13.1765 12 12.9997 12H0.999674C0.822863 12 0.653294 11.9298 0.52827 11.8047C0.403246 11.6797 0.333008 11.5101 0.333008 11.3333V0.666667C0.333008 0.489856 0.403246 0.320286 0.52827 0.195262C0.653294 0.0702379 0.822863 0 0.999674 0ZM1.66634 1.33333V10.6667H12.333V1.33333H1.66634ZM3.66634 6.66667H4.99967V9.33333H3.66634V6.66667ZM6.33301 2.66667H7.66634V9.33333H6.33301V2.66667ZM8.99967 4.66667H10.333V9.33333H8.99967V4.66667Z"
        fill="#545454"
      />
    </svg>
  );
};

export default IconAnalyticsBorder;
