import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import DotTypingLoader from "../../../Common/Components/Molecule/DotTypingLoader";
import styles from "../Styles.module.scss";
import Message from "./Message";

function MessageList({ messages = [], isLoadingResponse, cameraId }) {
  const messagesEndRef = useRef(null);
  useEffect(() => {
    const scrollToBottom = () => {
      messagesEndRef?.current?.scrollIntoView({ behavior: "smooth" });
    };
    scrollToBottom();
  }, [messages, isLoadingResponse]);

  return (
    <div className={styles["chat-messages"]}>
      {(messages || []).map((message, index) => (
        <Message
          message={message}
          key={message?.id || index}
          cameraId={cameraId}
        />
      ))}
      {isLoadingResponse && (
        <div className={styles.assistant}>
          <div className={`${styles.message} ${styles.typing}`}>
            <DotTypingLoader />
          </div>
        </div>
      )}
      <div ref={messagesEndRef} />
    </div>
  );
}
MessageList.propTypes = {
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.string,
      sender: PropTypes.string,
    })
  ).isRequired,
  isLoadingResponse: PropTypes.bool.isRequired,
  cameraId: PropTypes.string.isRequired,
};
export default MessageList;
