export const capitalizeInput = (e) => {
  let words = e.split(" ");

  words = words
    .map((ele) => {
      if (ele) {
        ele = ele[0]?.toUpperCase() + ele?.substring(1);
        return ele;
      }
    })
    .join(" ");

  return words;
};
