import React from "react";

const IconArrowNorth = ({ color = "#029262" }) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_43845_1895)">
        <path
          d="M2.91602 5.24984L3.73852 6.07234L6.41602 3.40067V12.8332H7.58268V3.40067L10.2602 6.07817L11.0827 5.24984L6.99935 1.1665L2.91602 5.24984Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_43845_1895">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconArrowNorth;
