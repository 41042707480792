import React from "react";

const IconScanner = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.25 0.25H13.75V4H12.25V1.75H9.25V0.25ZM4.75 0.25V1.75H1.75V4H0.25V0.25H4.75ZM9.25 13.75V12.25H12.25V10H13.75V13.75H9.25ZM4.75 13.75H0.25V10H1.75V12.25H4.75V13.75ZM0.25 6.25H13.75V7.75H0.25V6.25Z"
        fill="#DC3545"
      />
    </svg>
  );
};

export default IconScanner;
