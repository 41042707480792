export const PAGE_TITLES = {
  home: "Home",
  analytics: "Analytics",
  attendanceList: "Attendance List",
  task: "Tasks",
  taskDetails: "Task Details",
  checklist: "Checklist",
  forgotPassword: "Forgot Password?",
  changePassword: "Set Password",
  setupPassword: "Set Password",
  success: "Success",
  verifyEmail: "Verify Email",
  login: "Login",
  signup: "Signup",
  addCompany: "Add Company",
  addLocation: "Add Location",
  inviteYourTeam: "Invite Your Team",
  addCamera: "Add Camera",
  eventDetails: "Event Details",
  eventList: "Events",
  executiveDetails: "User Details",
  executiveView: "Executive View",
  myTasks: "My Tasks",
  taskSetup: "Task Setup",
  addEmployee: "Add User",
  integration: "Integrations",
  companyDetails: "Company Details",
  subscription: "Plan & Usage",
  advancedSettings: "Camera Stream",
  employeeList: "Users",
  employeeDetails: "User Details",
  employeeProfile: "User Profile",
  jolt: "Jolt Integration",
  location: "Locations",
  region: "Regions",
  schedule: "Schedules",
  station: "DriveThru",
  zone: "Person Suite",
  cities: "Cities",
  allTasks: "All Tasks",
  taskList: "Task List",
  ticketingDetails: "Ticket Details",
  tickets: "Tickets",
  cameraDetails: "Camera Details",
  cameraList: "Cameras",
  playback: "Playback",
  nvrDetails: "Recorder Details",
  nvr: "Recorders",
  liveView: "Live View",
  tat: "TAT",
  comparativeAnalytics: "Comparative Analytics",
  sso: "SSO Integration",
  locationHeatMap: "Location HeatMap"
};

// Maintain order of the host list, checking wobot should be done at the end of the loop
const hostSuffix = {
  airtel: "Airtel",
  openeye: "OpenEye",
  localhost: "Localhost",
  wobot: "Wobot AI",
};

export const checkWobotDomain = (title) => {
  for (const key in hostSuffix) {
    if (window.location.host.includes(key)) {
      title = `${title} | ${hostSuffix[key]}`;
      return title;
    }
  }

  return title;
};

export const faviconUpdate = async () => {
  const favicon = document.getElementById("favicon");

  const { host } = window.location;
  const subdomain = host.split(".")[0];

  for (const key in hostSuffix) {
    if (host.includes(key)) {
      if (key === "airtel") {
        favicon.href =
          "https://wobot-prod-application.s3.amazonaws.com/static/_cR2yQA_7Gypp5bq.svg";
        return;
      }
      if (key === "openeye") {
        favicon.href =
          "https://wobot-prod-application.s3.amazonaws.com/static/B93oaLA1R13KRxlY.svg";
        return;
      }
      if (key === "wobot") {
        switch (subdomain) {
          case "local":
            favicon.href = "/local-favicon.ico";
            return;
          case "dev":
            favicon.href = "/dev-favicon.ico";
            return;
          case "staging":
            favicon.href = "/staging-favicon.ico";
            return;
          case "app":
            favicon.href = "/app-favicon.ico";
            return;
          default:
            favicon.href = "/app-favicon.ico";
            return;
        }
      } else if (key === "localhost") {
        favicon.href = "/local-favicon.ico";
        return;
      }
    }
  }

  // If no match is found, set a default favicon
  favicon.href = "/favicon.ico";
};
