import React from "react";
import Skeleton from "react-loading-skeleton";
import PageTitleSkeleton from "../../../Molecule/PageTitleSkeleton";
import PosStatsSkeleton from "./PosStatsSkeleton";
import PosTableSkeleton from "./PosTableSkeleton";
import "../style.scss";

function PosListSkeleton() {
  return (
    <div className="wobot-panel-main">
      <div className="main-body-wrapper">
        <PageTitleSkeleton />

        <div className="">
          <Skeleton width={270} height={40} />
        </div>

        <PosStatsSkeleton />

        <PosTableSkeleton />
      </div>
    </div>
  );
}

export default PosListSkeleton;
