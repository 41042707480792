import React from "react";

const IconMute = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.9668 11.9337C17.9668 9.54697 16.6409 7.55802 14.6519 6.62984V9.54697L17.9668 12.8619C17.9668 12.4641 17.9668 12.1989 17.9668 11.9337ZM21.2818 11.9337C21.2818 13.1271 21.0166 14.3205 20.6188 15.3812L22.6077 17.3702C23.5359 15.779 23.9337 13.7901 23.9337 11.8011C23.9337 6.09945 19.9558 1.32597 14.6519 0.132597V2.91713C18.4972 4.2431 21.2818 7.69062 21.2818 11.9337ZM1.79004 0L0.0662842 1.72376L6.29833 7.95581H0.0662842V15.9116H5.37015L12 22.5415V13.6575L17.7016 19.3591C16.7734 20.0221 15.8453 20.5525 14.6519 20.9504V23.7349C16.5083 23.3371 18.0994 22.5415 19.558 21.3481L22.2099 24.0001L23.9337 22.2764L12 10.3427L1.79004 0ZM12 1.32597L9.21546 4.1105L12 6.89504V1.32597Z"
        fill="#545454"
      />
      <defs>
        <clipPath id="clip0">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconMute;
