import React from "react";

const IconLocationHollow = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99935 1.66699C6.77435 1.66699 4.16602 4.27533 4.16602 7.50033C4.16602 11.8753 9.99935 18.3337 9.99935 18.3337C9.99935 18.3337 15.8327 11.8753 15.8327 7.50033C15.8327 4.27533 13.2243 1.66699 9.99935 1.66699ZM5.83268 7.50033C5.83268 5.20033 7.69935 3.33366 9.99935 3.33366C12.2993 3.33366 14.166 5.20033 14.166 7.50033C14.166 9.90033 11.766 13.492 9.99935 15.7337C8.26602 13.5087 5.83268 9.87533 5.83268 7.50033Z"
        fill="#545454"
      />
      <path
        d="M9.99935 9.58366C11.1499 9.58366 12.0827 8.65092 12.0827 7.50033C12.0827 6.34973 11.1499 5.41699 9.99935 5.41699C8.84876 5.41699 7.91602 6.34973 7.91602 7.50033C7.91602 8.65092 8.84876 9.58366 9.99935 9.58366Z"
        fill="#545454"
      />
    </svg>
  );
};

export default IconLocationHollow;
