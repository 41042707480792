const drawPolygon = (ctx, coordinates, canvas, image, drawImageOnCanvas) => {
  function drawPolygon(
    ctx,
    coordinates,
    strokeColor = "#57D109",
    lineWidth = 2
  ) {
    ctx.beginPath();
    ctx.moveTo(coordinates[0][0], coordinates[0][1]);
    for (let i = 1; i < coordinates.length; i++) {
      ctx.lineTo(coordinates[i][0], coordinates[i][1]);
    }
    ctx.closePath();
    ctx.strokeStyle = strokeColor;
    ctx.lineWidth = lineWidth;
    ctx.stroke();
  }

  ctx.clearRect(0, 0, canvas.width, canvas.height);

  drawImageOnCanvas();

  coordinates.forEach((polygon) => {
    // Scale the coordinates based on the canvas size
    const scaledCoordinates = polygon.coordinates.map(([x, y]) => [
      x * canvas.width,
      y * canvas.height,
    ]);
    drawPolygon(ctx, scaledCoordinates, polygon.strokeColor, polygon.lineWidth);
  });
};

export default drawPolygon;
