import React, { useState, useCallback } from "react";
import { Button, Modal } from "react-bootstrap";
import Select from "react-select";
import IconClose from "../../../../Components/IconsComponent/IconClose";
import { colourStyles } from "../../../../../Services/colourStyles";
import {
  PriorityOptionsStringMap,
  contactOptions,
  getErrorMessage,
  intialRequestFormData,
  priorityOptions,
} from "./helper";
import UploadMedia from "./UploadMedia";
import styles from "./raiseAticket.module.scss";
import { validateEmpty } from "../../../../../Services/validation";
import { API_BASE_URL } from "../../../../../Constants";
import { showAlert } from "../../../../../Services/showAlert";
import callApi from "../../../../../Services/callApi";
import uploadMediaRequest from "./uploadMediaRequest";

function RaiseATicket({ show, onClose, userId, username }) {
  const [formData, setFormData] = useState(intialRequestFormData);
  const [errorFields, setErrorFields] = useState({});
  const [loading, setLoading] = useState(false);
  const { details, contactFor, subject, priority, media } = formData;

  const handleInputChange =
    ({ key, validate }) =>
    (e) => {
      const { value } = e.target;

      setErrorFields((prev) => ({
        ...prev,
        [key]: !validate(value) ? "" : getErrorMessage(key),
      }));

      setFormData((prev) => ({ ...prev, [key]: value }));
    };

  const saveTicketData = async () => {
    try {
      setLoading(true);
      const response = await callApi(
        `${API_BASE_URL}/customer/raiseQuery/${process.env.REACT_APP_CLICK_UP_ID}`,
        {
          method: "POST",
          body: JSON.stringify({
            subject,
            details,
            contactFor,
            priority,
            userId,
            username,
          }),
        },
        {
          callManifest: false,
          showLoader: false,
        }
      );
      if (response) {
        const taskId = response.data.id;
        if (taskId && media !== "") {
          await uploadMediaRequest({ attachment: media, taskId });
        }
        setLoading(true);
        showAlert("Successfully raised a support ticket", "success");
        setFormData(intialRequestFormData);
        onClose();
      }
    } catch (e) {
      showAlert(e, "error");
      setLoading(false);
    }
  };

  const validateAndSaveTicketData = useCallback(
    (event) => {
      event.preventDefault();

      const errors = {
        subject: validateEmpty(subject) ? getErrorMessage("subject") : "",
        details: validateEmpty(details) ? getErrorMessage("details") : "",
        contactFor: !contactFor ? getErrorMessage("contactFor") : "",
        priority: !priority ? getErrorMessage("priority") : "",
      };

      if (Object.keys(errors).some((key) => errors[key])) {
        setErrorFields(errors);
        return;
      }

      saveTicketData();
    },
    [formData]
  );

  return (
    <Modal show={show} className={styles.raiseATicketModal} onHide={onClose}>
      <form onSubmit={validateAndSaveTicketData}>
        <Modal.Header className="border-0 mb-4">
          <div className="modal-header-block">
            <h4>Raise a Support Request</h4>
            <p>Contact Wobot&apos;s Support Team for any issues or queries</p>
          </div>
          <button
            type="button"
            className="btn btn-single-icon btn-sm"
            onClick={onClose}
          >
            <IconClose />
          </button>
        </Modal.Header>
        <Modal.Body className={`modal-form ${styles.modalBody}`}>
          <div className={`col-12 p-0 ${styles.selectGroup}`}>
            <div className={`col-6 ${styles.pr10}`}>
              <label htmlFor="contactFor">Request type </label>
              <Select
                id="contactFor"
                name="contactFor"
                components={{
                  IndicatorSeparator: () => null,
                }}
                value={
                  contactFor
                    ? {
                        label: contactFor,
                        value: contactFor,
                      }
                    : undefined
                }
                styles={colourStyles}
                className={`react-select ${styles.selectStyles}`}
                options={contactOptions}
                placeholder="Select"
                onChange={(newValue) => {
                  if (newValue) {
                    setFormData((prev) => ({
                      ...prev,
                      contactFor: newValue.value,
                    }));
                  }
                }}
              />
              <p className={styles.textDanger}>
                {!contactFor && errorFields.contactFor}
              </p>
            </div>
            <div className={`col-6 ${styles.pl10}`}>
              <label htmlFor="priority">Priority </label>
              <Select
                id="priority"
                name="priority"
                components={{
                  IndicatorSeparator: () => null,
                }}
                value={
                  priority
                    ? {
                        label: PriorityOptionsStringMap[priority],
                        value: priority,
                      }
                    : undefined
                }
                styles={colourStyles}
                className={`react-select ${styles.selectStyles}`}
                options={priorityOptions}
                placeholder="Select"
                onChange={(newValue) => {
                  if (newValue) {
                    setFormData((prev) => ({
                      ...prev,
                      priority: newValue.value,
                    }));
                  }
                }}
              />
              <p className={styles.textDanger}>
                {!priority && errorFields.priority}
              </p>
            </div>
          </div>
          <div className="col-12 p-0">
            <div className={styles.labelContainer}>
              <label htmlFor="subject">Subject </label>
            </div>
            <input
              id="subject"
              name="subject"
              type="text"
              value={subject.charAt(0).toUpperCase() + subject.slice(1)}
              onChange={handleInputChange({
                key: "subject",
                validate: validateEmpty,
              })}
              className="form-control"
              placeholder="Enter subject"
              maxLength={140}
            />
            <p className={styles.textDanger}>{errorFields.subject}</p>
          </div>
          <div className="col-12 p-0">
            <label htmlFor="details">Details </label>
            <textarea
              id="details"
              name="details"
              value={details.charAt(0).toUpperCase() + details.slice(1)}
              onChange={handleInputChange({
                key: "details",
                validate: validateEmpty,
              })}
              className={`form-control ${styles.textareaStyles}`}
              placeholder="Provide details so we can help you better"
            />
            <p className={styles.textDanger}>{errorFields.details}</p>
          </div>
          <div className="col-12 p-0">
            <label htmlFor="media">
              Attach a file <span>(optional)</span>
            </label>
            <UploadMedia
              onUpload={(data) => setFormData({ ...formData, media: data })}
            />
          </div>
        </Modal.Body>
        <Modal.Footer
          className={`justify-content-end align-items-end ${styles.footerBtnGroup}`}
        >
          <Button
            onClick={onClose}
            variant="light"
            className={styles.cancelBtn}
            loading={loading}
            disabled={loading}
          >
            Cancel
          </Button>
          <Button loading={loading} disabled={loading} type="submit">
            Submit Request
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}

export default RaiseATicket;
