const themes = {
  openEye: {
    "--primary-color": "#000099",
    "--primary-color-dark": "#0096FA",
    "--primary-color-light": "#4774ba",
    "--primary-color-background": "rgba(0, 0, 153, 0.15)",
  },
};

export const getThemeColors = (name) =>
  themes[name] || {
    "--primary-color": "#3766E8",
    "--primary-color-dark": "#194DDE",
    "--primary-color-light": "#6588EB",
    "--primary-color-background": "rgba(55, 102, 232, 0.15)",
  };

export const getPrimaryColor = () =>
  getComputedStyle(document.documentElement).getPropertyValue(
    "--primary-color"
  );
