import React from "react";

const IconJourneyTime = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_41473_22655)">
        <path
          d="M3.66663 12C4.21891 12 4.66663 11.5523 4.66663 11C4.66663 10.4477 4.21891 10 3.66663 10C3.11434 10 2.66663 10.4477 2.66663 11C2.66663 11.5523 3.11434 12 3.66663 12Z"
          fill="#545454"
        />
        <path
          d="M8.33337 12C8.88566 12 9.33337 11.5523 9.33337 11C9.33337 10.4477 8.88566 10 8.33337 10C7.78109 10 7.33337 10.4477 7.33337 11C7.33337 11.5523 7.78109 12 8.33337 12Z"
          fill="#545454"
        />
        <path
          d="M10.6666 0.666992C9.07329 0.666992 7.67329 1.46699 6.83329 2.68033C6.55996 2.67366 6.28663 2.66699 5.99996 2.66699C3.05329 2.66699 0.666626 3.00033 0.666626 5.33366V12.0003C0.666626 12.587 0.926626 13.1137 1.33329 13.4803V14.667C1.33329 15.0337 1.63329 15.3337 1.99996 15.3337H2.66663C3.03329 15.3337 3.33329 15.0337 3.33329 14.667V14.0003H8.66663V14.667C8.66663 15.0337 8.96663 15.3337 9.33329 15.3337H9.99996C10.3666 15.3337 10.6666 15.0337 10.6666 14.667V13.4803C11.0733 13.1137 11.3333 12.587 11.3333 12.0003V9.94699C13.5933 9.62033 15.3333 7.68699 15.3333 5.33366C15.3333 2.75366 13.2466 0.666992 10.6666 0.666992ZM5.99996 4.00033H6.19329C6.13329 4.21366 6.08663 4.44033 6.05329 4.66033H2.22663C2.59329 4.30699 3.53996 4.00033 5.99996 4.00033ZM1.99996 5.99366H6.05329C6.15996 6.73366 6.43329 7.41366 6.83996 8.00033H1.99996V5.99366ZM9.99996 12.0003C9.99996 12.247 9.85996 12.4137 9.77329 12.487L9.57996 12.667H2.41996L2.22663 12.487C2.13996 12.4137 1.99996 12.247 1.99996 12.0003V9.33366H8.27329C8.79329 9.64699 9.37329 9.86032 9.99996 9.94699V12.0003ZM10.6666 8.66699C8.82663 8.66699 7.33329 7.17366 7.33329 5.33366C7.33329 3.49366 8.82663 2.00033 10.6666 2.00033C12.5066 2.00033 14 3.49366 14 5.33366C14 7.17366 12.5066 8.66699 10.6666 8.66699ZM11 2.66699H9.99996V6.00033L12.4133 7.44032L12.9133 6.62033L11 5.50033V2.66699Z"
          fill="#545454"
        />
      </g>
      <defs>
        <clipPath id="clip0_41473_22655">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconJourneyTime;
