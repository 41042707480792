import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import IconFullArrowRight from "../../../../Common/Components/IconsComponent/IconFullArrowRight";
import IconJourneyTime from "../../../../Common/Components/IconsComponent/IconJourneyTime";
import IconStationType from "../../../../Common/Components/IconsComponent/IconStationType";
import IconSuccessToast from "../../../../Common/Components/IconsComponent/IconSuccessToast";
import IconTime from "../../../../Common/Components/IconsComponent/IconTime";
import {
  DELETEDRIVETHRUJOURNEY,
  VERIFYCUSTOMERJOURNEY,
} from "../../../../Constants/AccessList";
import Roles from "../../../../Services/Roles";
import styles from "./StylesEventCard.module.scss";

function CarCard(props) {
  const history = useHistory();
  let newConfidence = "";
  if (props?.data?.confidenceScore > 0.4 || props.data.status === "verified") {
    newConfidence = "high";
  } else {
    newConfidence = "low";
  }

  const getTagColor = (confidence) => {
    confidence = confidence ? confidence.toLowerCase() : confidence;
    // if (!confidence || confidence === "medium") {
    //   return "tag-yellow";
    // } else

    if (confidence === "high") {
      return "tag-success-solid";
    }
    if (confidence === "low") {
      return "tag-orange-solid";
    }
  };
  const capitalizeFirstLetter = (word) => {
    word = word.toLowerCase();
    return word.charAt(0).toUpperCase() + word.slice(1);
  };

  return (
    <div className="col-xl-3 col-lg-3">
      <div className={`element-frame-wrapper ${styles.cardWrapper}`}>
        <div className={`element-frame-block ${styles.card_img_wrapper}`}>
          {/* Chcekbox */}

          <img
            alt="-"
            src={
              props?.data?.stations[0]?.entryImage
                ? props?.data?.stations[0]?.entryImage
                : "https://wobot-prod-application.s3.ap-south-1.amazonaws.com/public/placeholder.png"
            }
            className="img-fluid mx-auto d-block"
          />

          <div className={` ${styles.card_img_tag_wrapper} mt-2 pl-2 pr-2`}>
            <span
              className={`${
                Roles.authenticateViewAndAction(VERIFYCUSTOMERJOURNEY) &&
                props?.data?.status === "verified"
                  ? ""
                  : styles.tag_verified_hidden
              }`}
            >
              {" "}
              <OverlayTrigger
                key="top"
                placement="top"
                overlay={
                  <Tooltip id="tooltip}">
                    This car has been manually verified
                  </Tooltip>
                }
              >
                <div className={` ${styles.card_img_tag_wrapper_verified}`}>
                  <IconSuccessToast /> <span className="ml-1">Verified</span>
                </div>
              </OverlayTrigger>
            </span>

            {Roles.authenticateViewAndAction(VERIFYCUSTOMERJOURNEY) ? (
              ["top"].map((placement, index) => (
                <OverlayTrigger
                  key={index}
                  placement={placement}
                  overlay={
                    <Tooltip id={`tooltip-${placement}`}>
                      {` ${props?.data?.confidenceScore?.toFixed(3)}`}
                    </Tooltip>
                  }
                >
                  <div
                    className={`tag-block tag-lg ${getTagColor(newConfidence)}`}
                  >
                    {capitalizeFirstLetter(newConfidence)}
                  </div>
                </OverlayTrigger>
              ))
            ) : (
              <div className={`tag-block tag-lg ${getTagColor(newConfidence)}`}>
                {capitalizeFirstLetter(newConfidence)}
              </div>
            )}
          </div>
        </div>
        <div className={`element-frame-content ${styles.card_content_wrapper}`}>
          <div className={`${styles.card_content_header} mb-2 mt-1`}>
            <label className="fs-16 fw-500 mb-0">
              {props?.data?.licencePlate}
            </label>
            <div className={`${styles.card_action_icon_cont}`}>
              {Roles.authenticateViewAndAction(VERIFYCUSTOMERJOURNEY)
                ? ["top"].map((placement, index) => (
                    <OverlayTrigger
                      key={index}
                      placement={placement}
                      overlay={
                        <Tooltip id={`tooltip-${placement}`}>Edit</Tooltip>
                      }
                    >
                      <li
                        className="list-inline-item mr-3 cursor-pointer"
                        onClick={(e) => {
                          props.setUpdateCar(props.data);

                          props.setSelectedRow({
                            date: props?.data?.formattedDate,
                            id: props?.data?.journeyId,
                            location: props?.data?.location?._id,
                          });

                          props.getJourneyData(
                            props?.data.formattedDate,
                            props?.data?.journeyId,
                            props?.data?.location?._id
                          );
                          props.showModal();
                        }}
                      >
                        <IconPencil />
                      </li>
                    </OverlayTrigger>
                  ))
                : null}

              {Roles.authenticateViewAndAction(DELETEDRIVETHRUJOURNEY)
                ? ["top"].map((placement) => (
                    <OverlayTrigger
                      key={placement}
                      placement={placement}
                      overlay={
                        <Tooltip id={`tooltip-${placement}`}>Delete</Tooltip>
                      }
                    >
                      <li
                        className="list-inline-item cursor-pointer"
                        onClick={() => {
                          props.setDeleteJourneyId(props?.data?.journeyId);
                          props.setModalVisibility(true);
                        }}
                      >
                        <IconTrash />
                      </li>
                    </OverlayTrigger>
                  ))
                : null}
            </div>
          </div>
          {Roles.authenticateViewAndAction(VERIFYCUSTOMERJOURNEY) ? (
            <div className={`${styles.flexbox_wrapper} mb-2`}>
              <div
                className={`${styles.child_left} ${styles.flexblox_content}`}
              >
                <span>
                  <IconStationType />
                </span>
                <label
                  className={`fs-14 fw-400 ml-1 mb-0 ${styles.flexblox_content_label}`}
                >
                  Stations: {props?.data?.noOfStations}
                </label>
              </div>
              <div
                className={`${styles.child_right} ${styles.flexblox_content}`}
              >
                <span>
                  <IconJourneyTime />
                </span>
                <label
                  className={`fs-14 fw-400 ml-1 mb-0 ${styles.flexblox_content_label}`}
                >
                  Journey: {props?.data?.totalJourneyTime}
                </label>
              </div>
            </div>
          ) : (
            <></>
          )}
          <div className={`${styles.flexbox_wrapper}`}>
            <div className={`${styles.child_left} ${styles.flexblox_content}`}>
              <span>
                <IconTime />
              </span>
              <label className="fs-14 fw-400 ml-1 mb-0">
                {props?.data?.stations[0]?.formattedEntryTime}
              </label>
            </div>
            <div className={`${styles.child_right}`}>
              <Link
                to={{
                  pathname: `/drivethru/cars/journey/${props?.data?.journeyId}/${props?.data?.licencePlate}`,
                }}
              >
                View journey <IconFullArrowRight />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CarCard;
