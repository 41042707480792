import React from "react";

function IconPaymentMode({ width = "16", height = "16", color = "#545454" }) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.00323 14.6683C4.32132 14.6683 1.33655 11.6835 1.33655 8.00159C1.33655 4.31973 4.32132 1.33496 8.00323 1.33496C11.6851 1.33496 14.6699 4.31973 14.6699 8.00159C14.6699 11.6835 11.6851 14.6683 8.00323 14.6683ZM8.00323 13.3349C10.9488 13.3349 13.3366 10.9471 13.3366 8.00159C13.3366 5.05611 10.9488 2.66829 8.00323 2.66829C5.05769 2.66829 2.66988 5.05611 2.66988 8.00159C2.66988 10.9471 5.05769 13.3349 8.00323 13.3349ZM5.66988 9.33493H9.33656C9.52063 9.33493 9.6699 9.18573 9.6699 9.00159C9.6699 8.81753 9.52063 8.66826 9.33656 8.66826H6.66989C5.74941 8.66826 5.00321 7.92213 5.00321 7.00159C5.00321 6.08115 5.74941 5.33496 6.66989 5.33496H7.33656V4.00163H8.66989V5.33496H10.3366V6.66826H6.66989C6.48579 6.66826 6.33655 6.81753 6.33655 7.00159C6.33655 7.18573 6.48579 7.33493 6.66989 7.33493H9.33656C10.257 7.33493 11.0032 8.08113 11.0032 9.00159C11.0032 9.92213 10.257 10.6683 9.33656 10.6683H8.66989V12.0016H7.33656V10.6683H5.66988V9.33493Z"
        fill="#545454"
      />
    </svg>
  );
}

export default IconPaymentMode;
