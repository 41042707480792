import React from "react";

function IconWalkIn({ color = "#7E7E7E" }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.875 1.25C1.52982 1.25 1.25 1.52982 1.25 1.875V5.625C1.25 5.97018 0.970178 6.25 0.625 6.25C0.279822 6.25 0 5.97018 0 5.625V1.875C0 0.839466 0.839466 0 1.875 0H5.625C5.97018 0 6.25 0.279822 6.25 0.625C6.25 0.970178 5.97018 1.25 5.625 1.25H1.875Z"
        fill={color}
      />
      <path
        d="M13.75 0.625C13.75 0.279822 14.0298 0 14.375 0H18.125C19.1605 0 20 0.839466 20 1.875V5.625C20 5.97018 19.7202 6.25 19.375 6.25C19.0298 6.25 18.75 5.97018 18.75 5.625V1.875C18.75 1.52982 18.4702 1.25 18.125 1.25H14.375C14.0298 1.25 13.75 0.970178 13.75 0.625Z"
        fill={color}
      />
      <path
        d="M0.625 13.75C0.970178 13.75 1.25 14.0298 1.25 14.375V18.125C1.25 18.4702 1.52982 18.75 1.875 18.75H5.625C5.97018 18.75 6.25 19.0298 6.25 19.375C6.25 19.7202 5.97018 20 5.625 20H1.875C0.839466 20 0 19.1605 0 18.125V14.375C0 14.0298 0.279822 13.75 0.625 13.75Z"
        fill={color}
      />
      <path
        d="M19.375 13.75C19.7202 13.75 20 14.0298 20 14.375V18.125C20 19.1605 19.1605 20 18.125 20H14.375C14.0298 20 13.75 19.7202 13.75 19.375C13.75 19.0298 14.0298 18.75 14.375 18.75H18.125C18.4702 18.75 18.75 18.4702 18.75 18.125V14.375C18.75 14.0298 19.0298 13.75 19.375 13.75Z"
        fill={color}
      />
      <path
        d="M15 16.125H13.75V14.875C13.75 13.8394 12.9106 13 11.875 13H8.125C7.08947 13 6.25 13.8394 6.25 14.875V16.125H5V14.875C5 13.1491 6.39911 11.75 8.125 11.75H11.875C13.6009 11.75 15 13.1491 15 14.875V16.125ZM10 10.5C7.92893 10.5 6.25 8.82106 6.25 6.75C6.25 4.67893 7.92893 3 10 3C12.0711 3 13.75 4.67893 13.75 6.75C13.75 8.82106 12.0711 10.5 10 10.5ZM10 9.25C11.3807 9.25 12.5 8.13071 12.5 6.75C12.5 5.36929 11.3807 4.25 10 4.25C8.61929 4.25 7.5 5.36929 7.5 6.75C7.5 8.13071 8.61929 9.25 10 9.25Z"
        fill={color}
      />
    </svg>
  );
}

export default IconWalkIn;
